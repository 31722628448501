import { useState, useEffect } from 'react'
import { useFetch } from '../../utils/hooks'
import { Link } from 'react-router-dom'
import CategoryNavBar from '../../components/CategoryNavBar'
// import { data } from '../../data/Products'
import Loader from '../../components/Loader'
import { banners } from '../../data/Banners'
import Card from '../../components/Card'
import Banner from '../../components/Banner'
import { DisplayCategory } from '../../components/CategoryNavBar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import DialogBox from '../../components/DialogBox'

export const assetsLink =
  'https://store.kwetutech.com/webservices/api/v1/images/tempfiles/'
export const link =
  'https://store.kwetutech.com/webservices/api/v1/images/tempfiles/'

function Home() {
  const [loading, setLoading] = useState(true)
  const [sponsored, setSponsored] = useState([])

  const [isLoading, data, error] = useFetch(
    'https://store.kwetutech.com/webservices/api/v1/products'
  )

  const loadSponsored = () => {
    // console.log(10)
    const sponsoredProducts = data.filter(({ sponsored }) => {
      return sponsored === 'yes'
    })
    setSponsored(sponsoredProducts)
  }

  useEffect(() => {
    !isLoading && loadSponsored()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  // // const isLoading = () => {
  // //   setLoading(!loading)
  // // }
  // // loadSponsored(data)

  // //adds
  // const [playAnimation, setPlayAnimation] = useState(true)

  // // This will run one time after the component mounts
  // useEffect(() => {
  //   const onPageLoad = (state) => {
  //     setPlayAnimation(state)
  //   }

  //   // Check if the page has already loaded
  //   if (document.readyState === 'complete') {
  //     onPageLoad(!playAnimation)
  //     console.log(document.readyState)
  //   } else {
  //     onPageLoad(!playAnimation)
  //     // console.log(11)
  //     // console.log(document.readyState)
  //     // window.addEventListener('load', onPageLoad)
  //     // // Remove the event listener when component unmounts
  //     // return () => window.removeEventListener('load', onPageLoad)
  //   }
  // }, [])

  return (
    <div className="w-full p-3 lg:px-5 md:px-5 mb-20">
      <CategoryNavBar />

      <div className="w-full py-3 md:p-5">
        <Banner images={banners} />
      </div>

      {error && (
        <DialogBox
          className="hidden"
          state={true}
          title="Erreur de Connexion"
          content="Veiller verifier votre connexion internet puis reessayer"
          readOnly={true}
          // button={{
          //   title: 'Logout',
          //   handler: () => {
          //     // destroySession()
          //     // handlerLogout()
          //   },
          // }}
        />
      )}

      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col justify-center gap-5 px-3 py-3">
          {sponsored.length > 0 && (
            <DisplayCategory
              // onClick={loadSponsored()}
              icon={'star'}
              name={'Sponsored'}
            />
          )}
          <PerfectScrollbar className="flex gap-5">
            {/* <div className=""> */}
            {data &&
              sponsored.length > 0 &&
              sponsored.map(
                ({
                  product_category,
                  product_id,
                  product_name,
                  product_desc,
                  product_price,
                  product_state,
                  photo1,
                  sponsored,
                }) =>
                  sponsored && (
                    <Link
                      className=""
                      key={product_name + product_id}
                      to={'/products/' + product_id}
                    >
                      <Card
                        image={assetsLink + photo1}
                        name={product_name}
                        price={product_price}
                        desc={product_desc}
                        sponsored={sponsored}
                        state={product_state}
                      />
                    </Link>
                  )
              )}
            {/* </div> */}
          </PerfectScrollbar>

          <div className="w-full flex flex-col gap-5 items-center">
            <DisplayCategory
              // onClick={loadSponsored()}
              icon={'whatshot'}
              name={'Latest'}
            />
            <div className="flex w-full flex-wrap justify-center gap-5">
              {data &&
                data.length > 0 &&
                data.map(
                  (
                    {
                      product_category,
                      product_id,
                      product_name,
                      product_desc,
                      product_price,
                      product_state,
                      photo1,
                      sponsored,
                      suspended,
                    },
                    index
                  ) =>
                    index < 9 && (
                      <Link
                        className=""
                        key={product_name + product_id}
                        to={'/products/' + product_id}
                      >
                        <Card
                          image={assetsLink + photo1}
                          name={product_name}
                          price={product_price}
                          desc={product_desc}
                          sponsored={sponsored}
                          state={product_state}
                        />
                      </Link>
                    )
                )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Home
