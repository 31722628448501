import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { Link } from 'react-router-dom'

export default function MyModal({
  btnTitle,
  title,
  content,
  button,
  className,
  state,
  readOnly,
  reviewState,
}) {
  const [isOpen, setIsOpen] = useState(state ? state : false)

  function handlerButton() {
    button.handler && button.handler()
    setIsOpen(false)
  }

  function closeModal() {
    !reviewState && setIsOpen(false)
    reviewState && reviewState()
  }

  function openModal() {
    setIsOpen(true)
  }
  {
    /* <div className="fixed inset-0 flex items-center justify-center"> */
  }

  return (
    <>
      <div className="">
        <button
          type="button"
          onClick={openModal}
          className={
            className &&
            className +
              ' rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
          }
        >
          {btnTitle ? btnTitle : 'Open dialog'}
        </button>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {title ? title : 'Payment successful'}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {content
                        ? content
                        : 'Your payment has been successfully submitted. We’ve sent you an email with all of the details of your order.'}
                    </p>
                  </div>

                  <div className=" flex justify-between gap-2 mt-4">
                    {button && (
                      <Link
                        onClick={handlerButton}
                        to={button.link && button.link}
                      >
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          // onClick={closeModal}
                        >
                          {button.title}
                        </button>
                      </Link>
                    )}
                    {!readOnly && (
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={closeModal}
                      >
                        Annuler
                      </button>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
