import { SessionContext } from '../../utils/Context'
import { Navigate } from 'react-router-dom'
import DialogBox from '../../components/DialogBox'
import { useState } from 'react'
export default function Logout() {
  const [logout, setLogout] = useState(null)

  const handlerLogout = () => {
    setLogout(true)
  }

  return (
    <SessionContext.Consumer>
      {({ destroySession }) => (
        <div>
          <DialogBox
            className="hidden"
            state={true}
            title="Logout Alert"
            content="Voulez vous vraiment vous deconnecter??"
            button={{
              title: 'Logout',
              handler: () => {
                destroySession()
                handlerLogout()
              },
            }}
          />

          {logout ? (
            <Navigate to="/" replace={true} />
          ) : (
            <Navigate to="/store" replace={true} />
          )}
        </div>
      )}
    </SessionContext.Consumer>
  )
}
