import 'material-icons/iconfont/round.css'
import { useState, useEffect } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import logo from '../../assets/logo.png'
import { SessionContext } from '../../utils/Context'
import DialogBox from '../../components/DialogBox'

function Menu({ icon, title }) {
  return (
    <div className="flex flex-row w-full p-2 gap-2 rounded-xl md:hover:bg-slate-100">
      <span className="  lg:p-2 md:p-2 text-8 material-icons-round">
        {icon}
      </span>
      <span className=" hidden p-2 text-4 font-semibold items-center lg:flex">
        {title}
      </span>
    </div>
  )
}

function NetWorkBanner() {
  return (
    <div className="fixed bottom-0 left-0 right-0 w-full bg-red-700 flex justify-center items center z-30">
      <span className="font-momo text-bg md-max:text-3">
        {/* You are offline */}
        Vous n'êtes pas connecté à internet
        </span>
    </div>
  )
}

function Navbar() {
  const navigate = useNavigate()
  // const location = useLocation()
  // const isActive = location.isActivename
  const session = window.localStorage
  const [logout, setLogout] = useState(false)
  const [isActive, setIsActive] = useState(session.getItem('activeMenu'))

  const [isOnline, setIsOnline] = useState(navigator.onLine)

  const active = (menu) => {
    setIsActive(menu)
    // session.setItem('activeMenu', menu)
    // console.log(isHover)
  }

  // useEffect(() => {
  //   session.getItem('activeMenu')
  //     ? setIsActive(session.getItem('activeMenu'))
  //     : active('home')
  // }, [])

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine)
    }

    // Listen to the online status
    window.addEventListener('online', handleStatusChange)

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange)

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener('online', handleStatusChange)
      window.removeEventListener('offline', handleStatusChange)
    }
  }, [isOnline])

  // useEffect(() => {
  //   !isOnline && window.location.reload(false)
  // }, [isOnline])

  const handlerLogout = () => {
    setLogout(true)
  }

  console.log(isActive)
  return (
    <SessionContext.Consumer>
      {({ loggedUser, destroySession }) => (
        <div>
          <div className="fixed shadow-xxs sm-max:rounded-t-5 p-2 bottom-0 lg:left-0 md:left-0 bg-white flex flex-row lg:flex-col md:flex-col w-full lg:h-full md:h-full lg:border-r-2 md:border-r-2 lg:px-2 lg:py-4 md:px-2 md:py-4 justify-between lg:w-60 md:w-20 z-20">
            <NavLink
              to="/"
              onClick={() => active('home')}
              className=" hidden lg:flex md:flex flex-row items-center p-2 gap-2 "
            >
              <img src={logo} className=" w-10 h-10" alt="" srcSet="" />
              <span className="hidden p-2 text-4 font-semibold items-center lg:flex">
                My Kwetustore
              </span>
            </NavLink>
            <div className="flex w-full justify-around  flex-row ld:gap-2 md:gap-2 ld:flex-col md:flex-col ">
              <NavLink
                to="/"
                onClick={() => active('home')}
                className={isActive === 'home' ? 'rounded-xl bg-slate-100' : ''}
              >
                <Menu icon={'home'} title={'Home'} />
              </NavLink>
              <NavLink
                to="/search"
                onClick={() => active('search')}
                className={
                  isActive === 'search' ? 'rounded-xl bg-slate-100' : ''
                }
              >
                <Menu icon={'search'} title={'Search'} />
              </NavLink>
              <NavLink
                to="/stores"
                onClick={() => active('stores')}
                className={
                  isActive === 'stores' ? 'rounded-xl bg-slate-100' : ''
                }
              >
                <Menu icon={'store'} title={'Store'} />
              </NavLink>
              <NavLink
                to="/profile"
                onClick={() => active('profile')}
                className={
                  isActive === 'profile' ? 'rounded-xl bg-slate-100' : ''
                }
              >
                <Menu icon={'person'} title={'Profile'} />
              </NavLink>

              <NavLink
                to="/settings"
                onClick={() => active('settings')}
                className={
                  isActive === '/settings'
                    ? 'rounded-xl bg-slate-100'
                    : 'hidden'
                }
              >
                <Menu icon={'settings'} title={'Settings'} />
              </NavLink>

              {loggedUser && (
                <div>
                  {logout && (
                    <DialogBox
                      className="hidden"
                      state={true}
                      title="Logout Alert"
                      content="Voulez vous vraiment vous deconnecter??"
                      button={{
                        title: 'Logout',
                        handler: () => {
                          destroySession()
                          navigate('/')
                          setLogout(false)
                          // window.location.reload(false)
                        },
                      }}
                    />
                  )}
                  <NavLink onClick={handlerLogout}>
                    <Menu icon={'logout'} title={'Logout'} />
                  </NavLink>
                </div>
              )}
            </div>
            <div></div>
          </div>
          {!isOnline && <NetWorkBanner />}
        </div>
      )}
    </SessionContext.Consumer>
  )
}

export default Navbar

// className="fixed flex flex-col h-full w-1/4 justify-center items-center lg-max:flex-row lg-max:w-full lg-max:h-20"
