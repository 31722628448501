import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { CategoryProvider, SessionProvider } from './utils/Context'
import Navbar from './components/Navbar'
import Home from './pages/Home'
import Product from './pages/Product'
import Catgory from './pages/Category'
import Search from './pages/Search'
import Settings from './pages/Settings'
import Store from './pages/Store'
import MyStore from './pages/MyStore'
import Stores from './pages/Stores'
import Profile from './pages/Profile'
import Login from './pages/Login'
import Logout from './pages/Logout'
import Register from './pages/Register'
import Error from './pages/Error'
import Test from './pages/Test'
import ConfirmEmail from './pages/ConfirnEmail'
import './styles/index.css'
import reportWebVitals from './reportWebVitals'
// import 'material-icons/iconfont/round.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Router>
      <SessionProvider>
        <CategoryProvider>
          <Navbar />
          <div className="min-h-full flex lg:ml-60 md:ml-20  lg:px-5 md:px-5">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mykwetustore" element={<Home />} />
              <Route path="/products/:id" element={<Product />} />
              <Route path="/products" element={<Catgory />} />
              <Route path="/category/:category" element={<Catgory />} />
              <Route path="/search" element={<Search />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/stores" element={<Stores />} />
              <Route path="/stores/:id" element={<Store />} />
              <Route path="/mystore/:id" element={<MyStore />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/login" element={<Login />} />
              <Route path="/logout" element={<Logout />} />
              <Route path="/register" element={<Register />} />
              <Route path="/test" element={<Test />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </div>
        </CategoryProvider>
      </SessionProvider>
    </Router>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
