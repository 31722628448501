// import { writeJsonFile } from 'write-json-file'
import { data } from '../../data/Products'
// import fs from 'fs'

export default function Test() {
  //   const fs = require('fs')

  test()

  return <div>1212</div>
}
