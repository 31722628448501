import { useFetch } from '../../utils/hooks'
import { SessionContext } from '../../utils/Context'
import { DisplayCategory } from '../../components/CategoryNavBar'
import { Link, Navigate } from 'react-router-dom'
import Card from '../../components/Card'
import { useState } from 'react'
import Post from '../../components/Post'
import ProfileHeader from '../../components/ProfileHeader'
import EditProfile from '../../components/EditProfile'
import EditPassword from '../../components/EditPassword'
import CreateStore from '../../components/CreateStore'
import Loader from '../../components/Loader'
import { session } from '../../utils/Context'
import { assetsLink } from '../Home'
import DialogBox from '../../components/DialogBox'

function Profile() {
  const [postForm, setPostForm] = useState(false)
  const [editProfileForm, setEditProfileForm] = useState(false)
  const [editPasswordForm, setEditPasswordForm] = useState(false)
  const [createStoreForm, setCreateStoreForm] = useState(false)

  const [isLoading, data, error] = useFetch(
    session.getItem('myks-user') &&
      'https://store.kwetutech.com/api/controller.php?user_products&userId=' +
        JSON.parse(session.getItem('myks-user')).userId
  )

  // const [store, setStore] = useState()

  const showPostForm = () => {
    setPostForm(!postForm)
  }

  const showCreateStoreForm = () => {
    setCreateStoreForm(!createStoreForm)
  }

  const showEditPasswordForm = () => {
    setEditPasswordForm(!editPasswordForm)
    setEditProfileForm(!editProfileForm)
  }

  const showEditProfileForm = () => {
    setEditProfileForm(!editProfileForm)
  }
  // console.log(data)
  return (
    <SessionContext.Consumer>
      {({ loggedUser, loggedUserStore }) => (
        <div className="w-full">
          {loggedUser ? (
            isLoading ? (
              <Loader />
            ) : (
              <div className="w-full">
                {postForm && <Post handler={showPostForm} user={loggedUser} />}
                {editProfileForm && (
                  <EditProfile
                    user={loggedUser}
                    handler={showEditProfileForm}
                    showEditPasswordHandler={showEditPasswordForm}
                  />
                )}
                {editPasswordForm && (
                  <EditPassword handler={showEditPasswordForm}  user={loggedUser}/>
                )}
                {createStoreForm && (
                  <CreateStore
                    handler={showCreateStoreForm}
                    user={loggedUser}
                  />
                )}
                <div className="flex flex-col w-full p-2 md:p-5 mb-20">
                  <ProfileHeader
                    store={loggedUserStore}
                    user={loggedUser}
                    showPostHandler={showPostForm}
                    showEditProfileHandler={showEditProfileForm}
                    showCreateStoreHandler={showCreateStoreForm}
                  />
                  <div className="flex flex-col justify-center gap-5 px-3 py-4">
                    <DisplayCategory
                      // onClick={loadSponsored()}
                      icon={'whatshot'}
                      name={'My Products'}
                    />
                    <div className="flex flex-wrap gap-5">
                      {data && data.length > 0
                        ? data.map(
                            (
                              {
                                product_category,
                                product_id,
                                product_name,
                                product_desc,
                                product_price,
                                product_state,
                                photo1,
                                sponsored,
                              },
                              index
                            ) =>
                              index < 9 && (
                                <Link
                                  className=""
                                  key={product_name + product_id}
                                  to={'/products/' + product_id}
                                >
                                  <Card
                                    image={assetsLink + photo1}
                                    name={product_name}
                                    price={product_price}
                                    desc={product_desc}
                                    sponsored={sponsored}
                                    state={product_state}
                                  />
                                </Link>
                              )
                          )
                        : data.product_name && (
                            <Link
                              className=""
                              key={data.product_name + data.product_id}
                              to={'/products/' + data.product_id}
                            >
                              <Card
                                image={assetsLink + data.photo1}
                                name={data.product_name}
                                price={data.product_price}
                                desc={data.product_desc}
                                sponsored={data.sponsored}
                                state={data.product_state}
                              />
                            </Link>
                          )}
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : (
            <Navigate to="/login" replace={true} />
          )}
        </div>
      )}
    </SessionContext.Consumer>
  )
}

export default Profile
