/* eslint-disable react-hooks/exhaustive-deps */
import { SessionContext, StoreSession } from '../../utils/Context'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'
import Banner from '../../components/Banner'
import { banners } from '../../data/Banners'
import PasswordInput from '../../components/PasswordInput'
import LoadingBtn from '../../components/LoadingBtn'
import Button from '@mui/material/Button'
import TextInput from '../../components/TextInput'
import DialogBox from '../../components/DialogBox'
import ConfirmEmail from '../ConfirnEmail'
import RecoverPassword from '../RecoverPassword'

export const PostHeader = () => {
  const myHeaders = new Headers()
  myHeaders.set('Accept', 'application/json')
  myHeaders.set('Content-Type', 'multipart/form-data')
  myHeaders.set('Cache-Control', 'no-cache')
  myHeaders.set('Pragma', 'no-cache')
  myHeaders.set('Expires', '0')
  return myHeaders
}
export const GetHeader = () => {
  const myHeaders = new Headers()
  myHeaders.set('Cache-Control', 'no-cache')
  myHeaders.set('Pragma', 'no-cache')
  myHeaders.set('Expires', '0')
  return myHeaders
}

export default function Login() {
  //user
  // const navigate = useNavigate()
  // const [loginData, setLoginData] = useState()
  const [USER, setUSER] = useState()
  // const {data,isLoa}

  //state confirmEmail
  const [confirmEmailForm, setConfirmEmailForm] = useState(false)

  //state recovery
  const [recover, setRecover] = useState(false)
  const [RecoverPasswordForm, setRecoverPasswordForm] = useState(false)

  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)
  const [connectionError, setConnectionError] = useState(false)
  const [errorStatut, setErrorStatut] = useState()

  //input States
  const [usernameValue, setUsernameValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [filledUsername, setFilledUsername] = useState(false)
  const [filledPassword, setFilledPassword] = useState(false)

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  //error
  const [usernameError, setUsernameError] = useState({})
  const [passwordError, setPasswordError] = useState({})

  const handleUsernameError = (eState = false, eMessage = '') => {
    eState
      ? setUsernameError({ state: eState, message: '* ' + eMessage })
      : setUsernameError({})
  }
  const handlePasswordError = (eState = false, eMessage = '') => {
    eState
      ? setPasswordError({ state: eState, message: '* ' + eMessage })
      : setPasswordError({})
  }
  // const handleUsernameError = () => {}

  const handleUsernameChange = (e) => {
    setUsernameValue(e.target.value)
    handleUsernameError()
    checkUsername()
  }
  const handlePasswordChange = (e) => {
    // console.log(passwordValue)
    setPasswordValue(e.target.value)
    handlePasswordError()
    checkPassword()
  }
  const checkUsername = useCallback(() => {
    // console.log(60)
    // handleUsernameError()
    if (usernameValue.length >= 5) {
      // console.log(6)
      setFilledUsername(true)
      // handleUsernameError()
      CheckInputs()
    } else {
      setFilledUsername(false)
      handleUsernameError(true, 'Username Must have more than 6 characters')
    }
  })
  const checkPassword = useCallback(() => {
    // console.log(80)
    // handlePasswordError()
    if (passwordValue.length >= 7) {
      // console.log(8)
      setFilledPassword(true)
      // handlePasswordError()
      CheckInputs()
    } else {
      setFilledPassword(false)
      handlePasswordError(true, 'Password Must have more than 8 characters')
    }
  })
  const CheckInputs = () => {
    // console.log(filledUsername)
    // console.log('check')
    if (filledUsername === true && filledPassword === true) {
      handleChecks(true)
      // console.log(usernameValue)
      // console.log(passwordValue)
    } else {
      // console.log(1234)
      handleChecks(false)
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  //recover password handler
  const handleRecoverClick = () => {
    setRecoverPasswordForm(!RecoverPasswordForm)
  }

  const handleConnectionClick = () => {
    setConnectionError(false)
    setConnectionLoader(true)
    const formdata = new FormData()
    formdata.append('login_web', 'web_api')
    formdata.append('username_web', usernameValue.toLowerCase())
    formdata.append('password', passwordValue)
    // console.log(formdata['username'])
    Authenticate(formdata)
  }

  const errorHanler = () => {
    console.log(150)
    setConnectionLoader(false)
    setErrorStatut()
  }
  const ConfirmEmailHanler = () => {
    console.log(10)
    setConfirmEmailForm({ email: errorStatut.email })
    setConnectionLoader(false)
    setErrorStatut()
  }

  function Authenticate(User) {
    // console.log(User)
    let DATA
    fetch('https://store.kwetutech.com/api/authentication/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: User,
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        if (response.status) {
          //get the store
          fetch(
            'https://store.kwetutech.com/api/controller.php?fetch_single_shop&userId=' +
              response.userId,
            { headers: GetHeader }
          )
            .then((res) => res.json())
            .then((res) => {
              console.log(response.userId)
              setUSER(response)
              res[0]
                ? StoreSession(response, res[0])
                : StoreSession(response, null)
              // setConnectionLoader(false)
            })
            .catch((error) => {
              console.error('Error:', error)
              setConnectionLoader(false)
              setConnectionError(true)
            })
        } else {
          if (response.email) {
            // setErrorStatut(response)
            setConfirmEmailForm({ email: response.email })
            setConnectionLoader(false)
          } else {
            setErrorStatut({ message: response })
            setConnectionLoader(false)
            setRecover(true)
          }
        }
        // response.status && storeSession(response, null)
        // setConnectionLoader(false)
        // console.log(false)
        // return false
      })
      .catch((error) => {
        console.error('Error:', error)
        setConnectionError(true)
        setConnectionLoader(false)
      })
    return DATA
    // debugger
    // const user = users.find((user) => {
    //   if (user.username === User.username) {
    //     handleUsernameError()
    //     if (user.password === User.password) {
    //       const store = stores.find((store) => {
    //         return store.userId === user.user_id
    //       })
    //       storeSession(user, store)
    //       setUSER(user)
    //       navigate('/profile')
    //       return { user: user, store: store }
    //     } else {
    //       handlePasswordError(true, 'incorrect password')
    //       return null
    //     }
    //   } else {
    //     handleUsernameError(true, 'incorrect username')
    //     return null
    //   }
    // })
    // setConnectionLoader(false)
    // return user
  }

  // function authenticate(User) {
  //   const user = users.find((user) => {
  //     if (user.username === User.username) {
  //       handleUsernameError()
  //       if (user.password === User.password) {
  //         const store = stores.find((store) => {
  //           return store.userId === user.user_id
  //         })
  //         storeSession(user, store)
  //         setUSER(user)
  //         navigate('/profile')
  //         return { user: user, store: store }
  //       } else {
  //         handlePasswordError(true, 'incorrect password')
  //         return null
  //       }
  //     } else {
  //       handleUsernameError(true, 'incorrect username')
  //       return null
  //     }
  //   })
  //   setConnectionLoader(false)
  //   return user
  // }

  useEffect(() => {
    checkUsername()
    checkPassword()
  }, [usernameValue, passwordValue])

  // const lll = loggedUser

  return (
    <SessionContext.Consumer>
      {({ loggedUser, startSession }) => (
        <div className="w-full flex justify-center items-center md:p-5">
          {/* {USER || (loggedUser && <Navigate to="/profile" replace={true} />)} */}
          {connectionError && (
            <DialogBox
              className="hidden"
              state={true}
              title="Erreur de Connexion"
              content="Veiller verifier votre connexion internet puis reessayer"
              readOnly={true}
              // button={{
              //   title: 'Logout',
              //   handler: () => {
              //     // destroySession()
              //     // handlerLogout()
              //   },
              // }}
            />
          )}

          {errorStatut && (
            <DialogBox
              state={true}
              readOnly={true}
              btnTitle="Ma Boutique"
              className="w-full hidden"
              title={'Message'}
              content={errorStatut.message}
              button={{
                // link: '/store',
                title: 'Ok',
                handler: errorHanler,
              }}
            />
          )}

          {loggedUser ? (
            <Navigate to="/profile" replace={true} />
          ) : (
            <div className="flex flex-row justify-center items-center w-full h-full md:h-5/6  bg-tertiery rounded-xl p-5 md-max:mb-">
              <div className=" w-5/6 sm-max:w-full md:w-6/12 hidden md:block">
                <Banner images={banners} />
              </div>
              <div className=" w-5/6 sm-max:w-full md:w-6/12 ">
                {RecoverPasswordForm ? (<RecoverPassword handler={handleRecoverClick} />) : confirmEmailForm ? (
                  <ConfirmEmail email={confirmEmailForm} />
                ) : (
                  <div className="md:p-5 w-full flex flex-col justify-center items-center gap-3">
                    <span className="font-semibold text-8 text-primary ">
                      Connexion
                    </span>
                    <form className="w-full flex flex-col justify-center gap-3">
                      <TextInput
                        label="Username"
                        loader={connectionLoader}
                        inputValue={usernameValue}
                        handleInput={handleUsernameChange}
                        error={usernameError}
                      />

                      <PasswordInput
                        label="Password"
                        loader={connectionLoader}
                        inputValue={passwordValue}
                        handleInput={handlePasswordChange}
                        error={passwordError}
                      />

                      <LoadingBtn
                        text={'Connexion'}
                        loader={connectionLoader}
                        checked={inputChecked}
                        handleConnectionClick={() => {
                          const loginData = handleConnectionClick()
                          loginData && console.log(loginData)
                          loginData &&
                            startSession(loginData.user, loginData.store)
                          // loginData && console.log(loginData)
                          // loginData && window.location.reload(false)
                          // debugger
                        }}
                      />
                      <Link
                        to="/register"
                        className="w-full flex justify-center"
                      >
                        <Button className="w-full" variant="text">
                          Inscription
                        </Button>
                      </Link>
                      {recover && 
                        <Button onClick={handleRecoverClick} className="w-full" variant="text">
                          Mot de passe oublié ??
                        </Button>
                      }
                      {/* <DialogBox /> */}
                    </form>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </SessionContext.Consumer>
  )
}
