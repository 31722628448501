import 'material-icons/iconfont/round.css'
import { SessionContext } from '../../utils/Context'
import { useFetch } from '../../utils/hooks'
import styled from 'styled-components'

const StoreCover = styled.div`
  background-image: url(${(props) => props.img});
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`

export const storeAssetsLink = `https://store.kwetutech.com/webservices/api/v1/images/shops/`
export default function StoreHeader({ data, storeId, showEditStoreHandeler }) {
  // const [isLoading, data, error] = useFetch(
  //   'https://store.kwetutech.com/api/controller.php?fetch_shop&shop_id=' +
  //     storeId
  // )
  // console.log(data)
  return (
    <SessionContext.Consumer>
      {({ loggedUser, loggedUserStore }) => (
        <div className="w-full min-h-40 flex gap-2 md:gap-5 justify-center bg-tertiery rounded-xl shadow-sm p-2 md:p-5">
          <div className="flex justify-center items-center">
            <StoreCover
              img={data.shop_cover && storeAssetsLink + data.shop_cover}
              className="flex justify-center items-center h-20 w-20 sm:h-24 sm:w-24 md:h-30 md:w-30 bg-bg rounded-full material-icons-round text-12 sm:text-16 md:text-20 text-primary"
            >
              {data.shop_cover ? '' : 'store'}
            </StoreCover>
          </div>
          <div className="flex flex-col gap-2 justify-center items-center">
            <div className="flex flex-col  gap-2 justify-center">
              <div className="flex flex-row gap-5 items-center">
                <span className="text-primary text-5  sm:text-6 md:text-8 font-bold">
                  {data ? data.shop_name : 'My KwetuStore'}
                </span>
                <span className="material-icons-round text-blue-500 md-max:text-6 text-8">
                  verified
                </span>
              </div>
              <div className="flex flex-col md:flex-row gap-2 md:gap-5">
                <div className="flex gap-1 justify-center items-center bg-tertiety text-center text-secondary font-semibold sm-max:px-2 sm-max:text-3 px-3 py-1 rounded-2 cursor-pointer  shadow-xxs">
                  <span className="material-icons-round text-4">
                    location_on
                  </span>
                  <span className="">{data.shop_address}</span>
                </div>

                <a
                  target="_blank"
                  rel="noreferrer"
                  href={'https://wa.me/' + data.shop_contacts}
                >
                  <div className="flex gap-1 justify-center items-center bg-tertiety text-center text-secondary font-semibold sm-max:px-2 sm-max:text-3 px-3 py-1 rounded-2 cursor-pointer hover:bg-primary hover:text-tertiery  shadow-xxs">
                    <span className="material-icons-round text-4">call</span>
                    <span className="">
                      {data ? data.shop_contacts : '+243991097363'}
                    </span>
                  </div>
                </a>
                {loggedUserStore &&
                  loggedUserStore.shop_id === data.shop_id && (
                    <div
                      onClick={showEditStoreHandeler}
                      className="flex gap-1 justify-center items-center bg-tertiery text-center text-secondary font-semibold sm-max:px-2 sm-max:text-3 px-3 py-1 rounded-2 cursor-pointer hover:bg-primary hover:text-tertiery shadow-xxs"
                    >
                      <span className="material-icons-round text-4">edit</span>
                      <span className="">Edit My Store</span>
                    </div>
                  )}
              </div>
            </div>
            <div className="flex max-w-120 justify-center px-2 md:px-5">
              <span className="text-center text-secondary font-semibold text-3 md:text-4">
                {data.shop_description}
              </span>
            </div>
          </div>
        </div>
      )}
    </SessionContext.Consumer>
  )
}
