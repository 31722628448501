import { useState, useEffect, useContext } from 'react'
import { GetHeader } from '../../pages/Login'
import { ThemeContext } from '../Context'

export function useFetch(url) {
  const [data, setData] = useState({})
  const [error, setError] = useState(false)

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (!url) return

    async function fetchData() {
      try {
        const response = await fetch(url, {
          headers: GetHeader,
        })

        const data = await response.json()
        data.length === 1 ? setData(data[0]) : setData(data)
      } catch (error) {
        console.log(error)
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    setLoading(true)

    fetchData()
  }, [url])

  return [isLoading, data, error]
}
export function useFetch2(url) {
  const [data, setData] = useState({})
  const [error, setError] = useState(false)

  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    if (!url) return

    async function fetchData() {
      try {
        const response = await fetch(url)

        const data = await response.json()
        data.length === 1 ? setData(data[0]) : setData(data)
      } catch (error) {
        console.log(error)
        setError(true)
      } finally {
        setLoading(false)
      }
    }

    setLoading(true)

    fetchData()
  }, [url])

  return [data, error, isLoading]
}

export function useTheme() {
  const { theme, toggleTheme } = useContext(ThemeContext)
  //console.log(theme)
  return { theme, toggleTheme }
}

// export function PostData(link, data) {
//   // https://store.kwetutech.com/api/controller.php?fetch_single_shop&userId=1

//   fetch(link, {
//     method: 'POST',
//     headers: Header,
//     body: data,
//   })
//     .then((response) => response.json())
//     .then((response) => {
//       console.log(response)
//       response.status
//       setConnectionLoader(false)
//     })
//     .catch((error) => {
//       console.error('Error:', error)
//     })
// }

const Header = () => {
  const myHeaders = new Headers()
  myHeaders.set('Accept', 'application/json')
  myHeaders.set('Content-Type', 'multipart/form-data')
  myHeaders.set('Cache-Control', 'no-cache')
  myHeaders.set('Pragma', 'no-cache')
  myHeaders.set('Expires', '0')
  return myHeaders
}
