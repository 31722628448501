/* eslint-disable react-hooks/exhaustive-deps */
// import styled from '@emotion/styled'
import { Link, Navigate } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'

// import { banners } from '../../data/Banners'
import { SessionContext } from '../../utils/Context'
import PasswordInput from '../../components/PasswordInput'
import LoadingBtn from '../../components/LoadingBtn'
import Button from '@mui/material/Button'
import TextInput from '../../components/TextInput'
import { PostHeader } from '../Login'
import DialogBox from '../../components/DialogBox'
import { StoreSession } from '../../utils/Context'

export default function ConfirmEmail(email) {
  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)
  // console.log(email)
  //fetch states
  const [fetchStatut, setFetchStatut] = useState(false)
  const [errorStatut, setErrorStatut] = useState()

  //input value States

  const [telephoneValue, setTelephoneValue] = useState('')

  //filled input States

  const [filledTelephone, setFilledTelephone] = useState(false)

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  //error
  const [telephoneError, setTelephoneError] = useState({})

  //userId

  //Changes Handleres

  const handleTelephoneChange = (e) => {
    setTelephoneValue(e.target.value)
    checkTelephone()
  }

  const checkTelephone = useCallback(() => {
    // const phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
    // const phoneno2 = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{3})$/
    if (telephoneValue.length >= 2) {
      setFilledTelephone(true)
      CheckInputs()
    } else {
      setFilledTelephone(false)
    }
  })

  //Inputs General Check
  const CheckInputs = () => {
    if (filledTelephone === true) {
      handleChecks(true)
    } else {
      // console.log(1234)
      handleChecks(false)
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  const handleConnectionClick = () => {
    setConnectionLoader(true)

    //formData
    setConnectionLoader(true)
    const formdata = new FormData()
    formdata.append('reverify_email', 'reverify_email')
    formdata.append('email', email.email.email)
    formdata.append('code', telephoneValue)

    postData(formdata)
  }
  const handleResendCode = () => {
    setConnectionLoader(true)

    //formData
    setConnectionLoader(true)
    const formdata = new FormData()
    formdata.append('resend_verification_email', 'resend_verification_email')
    formdata.append('email', email.email.email)
    postData(formdata)
  }

  const refreshHanler = () => {
    setConnectionLoader(false)
    setFetchStatut(false)
    window.location.reload(false)
  }
  const errorHanler = () => {
    setConnectionLoader(false)
    setErrorStatut()
  }

  //post data
  const postData = (data) => {
    // console.log('posttt')
    fetch('https://store.kwetutech.com/api/authentication/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status) {
          // console.log(response)
          StoreSession(response)
          setConnectionLoader(false)
          // setFetchStatut(true)
        } else {
          setErrorStatut({
            message: response === 'failed' ? 'Code incorrect' : response,
          })
        }
        // setConnectionLoader(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setErrorStatut({
          message:
            'OPPS Something went wrong, please check your network and retry later',
        })
      })
    // setConnectionLoader(false)
  }

  useEffect(() => {
    checkTelephone()
    CheckInputs()
  }, [telephoneValue, inputChecked])

  const testIt = () => {
    console.log(filledTelephone)
  }

  return (
    <div className="md:p-5 max-w-125 w-full flex flex-col justify-center items-center gap-3">
      {!email && <Navigate to="/login" replace={true} />}
      <span className="font-semibold text-8 text-primary ">
        Email Verification
      </span>
      <span className=" text-4 text-center text-primary ">
        Un code de verification a été envoyé sur{' '}
        <span className="font-semibold">{email.email.email + ' '}</span> pour
        verifier votre compte
      </span>
      <form className="w-full flex flex-col justify-center gap-3">
        <TextInput
          label="Entrer le Code de Verification"
          loader={connectionLoader}
          inputValue={telephoneValue}
          handleInput={handleTelephoneChange}
        />
        <LoadingBtn
          text="VERIFIER"
          loader={connectionLoader}
          checked={true}
          handleConnectionClick={handleConnectionClick}
        />
        <LoadingBtn
          text="Renvoyer le code"
          loader={connectionLoader}
          checked={true}
          handleConnectionClick={handleResendCode}
          variant="text"
        />
        {/* <Button onClick={testIt} className="w-full" variant="text">
                test
              </Button> */}
        {/* <DialogBox /> */}
      </form>
      {errorStatut && (
        <DialogBox
          state={true}
          readOnly={true}
          btnTitle="Ma Boutique"
          className="w-full hidden"
          title={'Message'}
          content={errorStatut.message}
          button={{
            // link: '/store',
            title: 'Ok',
            handler: errorHanler,
          }}
        />
      )}
    </div>
  )
}
