/* eslint-disable react-hooks/exhaustive-deps */
import { createContext } from 'react'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { categories } from '../../data/Products/Categories'
export const session = window.localStorage

export const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState('light')
  const toggleTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const SurveyContext = createContext()

export const SurveyProvider = ({ children }) => {
  const [answers, setAnswers] = useState({})
  const saveAnswers = (newAnswers) => {
    setAnswers({ ...answers, ...newAnswers })
  }

  return (
    <SurveyContext.Provider value={{ answers, saveAnswers }}>
      {children}
    </SurveyContext.Provider>
  )
}

export const CategoryContext = createContext()

export const CategoryProvider = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState('')

  const selectCategory = (category) => {
    setSelectedCategory(category)
    // console.log(category)
  }

  return (
    <CategoryContext.Provider value={{ selectedCategory, selectCategory }}>
      {children}
    </CategoryContext.Provider>
  )
}

export const SessionContext = createContext()

export const StoreSession = (user, store = null) => {
  // const navigate = useNavigate()
  const session = window.localStorage
  session.setItem('myks-user', JSON.stringify(user))
  session.setItem('myks-store', JSON.stringify(store))
  window.location.reload(false)
  // navigate('/profile')
}
export const StoreSessionUserStore = (store) => {
  const session = window.localStorage
  session.setItem('myks-store', JSON.stringify(store))
}

export const DestroySession = () => {
  // const session = window.localStorage
  // const navigate = useNavigate()
  session.removeItem('myks-user')
  session.removeItem('myks-store')
  clearCacheData()
  window.location.reload(false)
  // navigate('/')
}

export const SessionProvider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState()
  const [loggedUserStore, setLoggedUserStore] = useState()

  const startSession = (user, store = null) => {
    setLoggedUser(user)
    setLoggedUserStore(store)
    // StoreSession(user, store)
    // debugger
  }

  // const loadStore = (store) => {
  //   setLoggedUserStore(store)
  // }

  const destroySession = () => {
    session.removeItem('myks-user')
    session.removeItem('myks-store')
    clearCacheData()
    setLoggedUser()
    setLoggedUserStore()
  }

  useEffect(() => {
    !loggedUser && startSession(
      JSON.parse(session.getItem('myks-user')),
      JSON.parse(session.getItem('myks-store'))
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startSession, destroySession])

  return (
    <SessionContext.Provider
      value={{ loggedUser, loggedUserStore, startSession, destroySession }}
    >
      {children}
    </SessionContext.Provider>
  )
}

export const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name)
    })
  })
  // alert('Complete Cache Cleared')
}
