import styled from 'styled-components'
import cover from '../../assets/placeholder.jpg'
import 'material-icons/iconfont/round.css'

const CardCover = styled.div`
  // height: 100px;
  // width: 100px;
  background-image: url(${(props) => props.img || cover});
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`

export default function Card({ image, name, price, desc, sponsored }) {
  // console.log(image)
  return (
    <div className="flex flex-row w-full md:max-w-100 h-50 sm:w-full sm:h-60 md:max-h0  bg-tertiery-500 hover:shadow-xs hover:bg-bg rounded-2xl ">
    {/* <div className="flex flex-row w-full h-50 sm:h-60 sm:w-full md-max:w-full  lg:h-60 lg:w-90 bg-red-500 hover:shadow-xs hover:bg-bg rounded-2xl "> */}
      <CardCover
        className="flex h-full p-2 w-40 bg-white rounded-l-2xl"
        img={image}
      >
        {sponsored && (
          <span className="w-10 material-icons-round h-6 ml-30 rounded-2 text-4 text-orange-500 items-center justify-center flex bg-bg">
            star
          </span>
        )}
      </CardCover>
      <div className="w-full flex flex-col justify-center gap-3 p-2   ">
        <div className="flex flex-col">
          <div className="flex w-full">
            <span className="flex justify-center text-3 sm:text-4 align-middle px-2 py-1 rounded-2  font-semibold  text-tertiery bg-primary">
              {price} $
            </span>
          </div>
          <span className=" sm-max:text-4 text-5 text-primary-500 font-bold">
            {name}
          </span>
          <span className=" text-3 sm:text-4 font-medium text-secondary-500 ">
            {desc.substr(0, 20)}{' '}
            <span className="text-blue-300">Read more...</span>
          </span>
        </div>
      </div>
    </div>
  )
}
