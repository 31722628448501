/* eslint-disable react-hooks/exhaustive-deps */
import { useFetch } from '../../utils/hooks'
import 'material-icons/iconfont/round.css'
import { useState, useEffect, useCallback } from 'react'
import { shortCategories } from '../../data/Products/Categories'
import TextInput from '../TextInput'
import ImageUpload from '../ImageUpload'
import PerfectScrollbar from 'react-perfect-scrollbar'
import LoadingBtn from '../LoadingBtn'
import Loader from '../Loader'
import DialogBox from '../../components/DialogBox'
import { PostHeader } from '../../pages/Login'
import { StoreSession } from '../../utils/Context'


export default function EditProfile({
  user,
  handler,
  showEditPasswordHandler,
}) {
  //file data
  const [product, setProduct] = useState({})

  const [isLoading, data, error] = useFetch(
    'https://store.kwetutech.com/webservices/api/v1/user/' + user.userId
  )

  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)

  //input value States
  const [firstNameValue, setFirstNameValue] = useState('')
  const [lastNameValue, setLastNameValue] = useState('')
  const [telephoneValue, setTelephoneValue] = useState('')

   //errors
   const [errorStatut, setErrorStatut] = useState()

  // console.log(data)

  useEffect(() => {
    if (data.user_id) {
      setFirstNameValue(data.first_name)
      setLastNameValue(data.last_name)
      setTelephoneValue(data.telephone)
    }
  }, [data])

  //filled input States
  const [filledFirstName, setFilledFirstName] = useState(false)
  const [filledLastName, setFilledLastName] = useState(false)
  const [filledTelephone, setFilledTelephone] = useState(false)

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  //Changes Handleres
  const handleFirstNameChange = (e) => {
    setFirstNameValue(e.target.value)
    // console.log(firstNameValue)
    checkFirstName()
  }
  const handleLastNameChange = (e) => {
    setLastNameValue(e.target.value)
    checkLastName()
  }

  const handleTelephoneChange = (e) => {
    setTelephoneValue(e.target.value)
    checkTelephone()
  }

  //Check handlers
  const checkFirstName = useCallback(() => {
    if (firstNameValue.length >= 4) {
      setFilledFirstName(true)
      CheckInputs()
    } else {
      setFilledFirstName(false)
    }
  })

  const checkLastName = useCallback(() => {
    if (lastNameValue.length >= 4) {
      setFilledLastName(true)
      CheckInputs()
    } else {
      setFilledLastName(false)
    }
  })
  const checkTelephone = useCallback(() => {
    // const phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
    // const phoneno2 = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{3})$/
    if (telephoneValue.length >= 10) {
      setFilledTelephone(true)
      CheckInputs()
    } else {
      setFilledTelephone(false)
    }
  })
  //Inputs General Check
  const CheckInputs = () => {
    if (
      filledFirstName === true &&
      filledLastName === true &&
      filledTelephone === true
    ) {
      handleChecks(true)
    } else {
      // console.log(1234)
      handleChecks(false)
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  const handleConnectionClick = () => {
    // formData
    setConnectionLoader(true)
    const formdata = new FormData()
    // formdata.append('verification', 'rupdate')
    formdata.append('update_user', 'update_user')
    formdata.append('user_id', user.userId)
    formdata.append('first_name', firstNameValue)
    formdata.append('last_name', lastNameValue)
    formdata.append('telephone', telephoneValue)
    postData(formdata)
  }


  //fetch
  const postData = (data) => {
    // console.log('posttt')
    fetch('https://store.kwetutech.com/api/authentication/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        if (response.status) {
          StoreSession(response)
          setErrorStatut({
            message: 'Votre Profile a été modifié avec succèss',
            action: 'reload',
          })
          // setFetchStatut(true)
        } else {
          setErrorStatut({
            message: response === 'failed' ? 'Opps error while upadating your profile' : response,
            
          })
        }
        // setConnectionLoader(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setErrorStatut({
          message:
            'OPPS Something went wrong, please check your network and retry later',
        })
      })
    // setConnectionLoader(false)
  }

  const errorHanler = () => {
    setConnectionLoader(false)
    if(errorStatut.action && errorStatut.action === 'reload') window.location.reload = false
    setErrorStatut()
    
  }

  //effect listener
  useEffect(() => {
    checkFirstName()
    checkLastName()
    checkTelephone()
  }, [firstNameValue, lastNameValue, telephoneValue])

  const testIt = () => {
    console.log(filledFirstName)
    console.log(filledLastName)
    console.log(filledTelephone)
  }

  return (
    <div className="fixed flex justify-center items-center md-max:h-full md:min-h-full w-full bg-bg bg-opacity-90 pb-5 ">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex justify-center items-center h-full w-full md:mr-30 lg:mr-68 p-2 md:p-5 ">
          <div className="bg-tertiery flex flex-col h-full w-full rounded-xl shadow-xs p-2 md:p-5">
            <PerfectScrollbar className="h-full w-full md-max:mb-10 md-max:overflow-y-scroll">
              <div className="min-h-10 flex justify-between items-center mb-2">
                <div className="w-4/6 flex flex-wrap justify-between gap-2 ml-5">
                  <span className="md:text-6 font font-semibold text-primary">
                    Modifier Le Profile
                  </span>
                  <div
                    onClick={showEditPasswordHandler}
                    className="flex gap-1 justify-center items-center p-1 px-2 rounded-2 shadow-xxs cursor-pointer text-primary hover:bg-primary hover:text-tertiery-500"
                  >
                    <button className="material-icons-round text-4 font-semibold ">
                      edit
                    </button>
                    <span className="font-semibold  text-4  ">
                      Edit Password
                    </span>
                  </div>
                </div>
                <button
                  onClick={handler}
                  className="material-icons-round text-10 font-semibold text-primary hover:text-red-500"
                >
                  close
                </button>
              </div>

              <div className="flex flex-col gap-5  p-2 md:">
                <div className=" w-full flex flex-col md:flex-row  gap-3">
                  <TextInput
                    label="First Name"
                    loader={connectionLoader}
                    inputValue={firstNameValue}
                    handleInput={handleFirstNameChange}
                  />
                </div>
                <div className=" w-full flex flex-col md:flex-row  gap-3">
                  <TextInput
                    label="Last Name"
                    loader={connectionLoader}
                    inputValue={lastNameValue}
                    handleInput={handleLastNameChange}
                  />
                </div>

                <div className="flex flex-col gap-3 w-full">
                  <TextInput
                    label="Telephone"
                    loader={connectionLoader}
                    inputValue={telephoneValue}
                    handleInput={handleTelephoneChange}
                  />
                </div>
              </div>

              <div className="w-full flex justify-center p-2">
                <LoadingBtn
                  text="Enregistrer"
                  loader={connectionLoader}
                  checked={inputChecked}
                  handleConnectionClick={handleConnectionClick}
                />
              </div>
              {/* <button onClick={testIt}>text</button> */}
            </PerfectScrollbar>
          </div>
          {errorStatut && (
            <DialogBox
              state={true}
              readOnly={true}
              btnTitle="Ma Boutique"
              className="w-full hidden"
              title={'Message'}
              content={errorStatut.message}
              button={{
                // link: '/store',
                title: 'Ok',
                handler: errorHanler,
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}
