import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { Collapse } from '@mui/material'
import { useState } from 'react'

export default function MyAlert({ type, message }) {
  const [open, setOpen] = useState(true)
  return (
    <div className="flex justify-center p-2 duration-500">
      <Collapse orientation="vertical" in={open} collapsedSize={0}>
        <Alert
          severity={type || 'error'}
          onClose={() => {
            setOpen(false)
          }}
        >
          {type && <AlertTitle>{message.toUpperCase()}</AlertTitle>}
          {/* {message} */}
        </Alert>
      </Collapse>
    </div>
  )
}
