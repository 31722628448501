import { useState, useRef } from 'react'
import { shortCategories } from '../../data/Products/Categories'
import { CategoryContext } from '../../utils/Context'
import { NavLink, useLocation } from 'react-router-dom'
import 'material-icons/iconfont/round.css'
import styled from 'styled-components'

export function Category({ name, cat, icon, selectedCategory }) {
  // console.log(selectedCategory)
  return (
    <div
      className={
        selectedCategory === cat
          ? 'bg-blue-600 text-white flex p-3 h-7 justify-center items-center rounded-full hover:bg-blue-600 hover:text-white font-semibold'
          : 'flex p-3 h-7 justify-center items-center rounded-full bg-white hover:bg-blue-600 hover:text-white font-semibold'
      }
    >
      <div className=" flex flex-row justify-center items-center px-2 py-1 gap-2 ">
        {icon && <img className="w-6 h-6" src={icon} alt="" />}
        {/* <div className="w-6 h-6" style={{ backgroundImage: icon }}></div> */}
        <div className="flex items-center justify-center whitespace-nowrap">
          {name}
        </div>
      </div>
    </div>
  )
}
export function DisplayCategory({ name, icon }) {
  // console.log(selectedCategory)
  return (
    <div className="flex p-3 h-7 w-36 justify-center items-center rounded-full bg-white font-semibold cursor-pointer">
      <div className=" flex flex-row justify-center items-center px-2 py-1 gap-2 ">
        {icon && (
          <span className="material-icons-round text-orange-500 text-5">
            {icon}
          </span>
        )}
        <div className="flex items-center justify-center whitespace-nowrap">
          {name}
        </div>
      </div>
    </div>
  )
}

const MyDiv = styled.div`
  scroll-behavior: smooth;
`

// const scro
const rightScroll = () => {
  //
}

export default function CategoryNavBar() {
  const Nav = useRef()
  // const [clicked, setClicked] = useState(false)
  const [leftScroll, setLeftScroll] = useState(false)
  const [rightScroll, setRightScroll] = useState(true)
  const handleRightClick = () => {
    setLeftScroll(true)
    setRightScroll(false)
    Nav.current.scrollLeft = Nav.current.scrollWidth
    // console.log(Nav.current.scrollLeft)
  }
  const handleLeftClick = () => {
    setLeftScroll(false)
    setRightScroll(true)
    Nav.current.scrollLeft -= Nav.current.scrollWidth
  }

  return (
    <CategoryContext.Consumer>
      {({ selectedCategory, selectCategory }) => (
        <MyDiv
          className="flex joker category-nav-bar flex-row justify-between items-center gap-3 overflow-x-scroll overflow-y-hidden"
          ref={Nav}
        >
          {leftScroll && (
            <span
              onClick={handleLeftClick}
              className="absolute hidden material-icons-round  rounded-full md:flex justify-center items-center h-8 p-2 bg-zinc-200 hover:bg-zinc-400 cursor-pointer"
            >
              keyboard_arrow_left
            </span>
          )}
          {rightScroll && (
            <span
              onClick={handleRightClick}
              className="absolute hidden material-icons-round right-5 rounded-full md:flex justify-center items-center h-8 p-2  bg-zinc-200 hover:bg-zinc-400 cursor-pointer"
            >
              keyboard_arrow_right
            </span>
          )}
          <NavLink to={'/products'} onClick={() => selectCategory('')}>
            <Category
              selectedCategory={selectedCategory}
              name={'All'}
              cat={'Category'}
            />
          </NavLink>
          {shortCategories.map(({ name, category, icon, key }) => {
            return (
              <NavLink
                to={'/category/' + category}
                key={category + key}
                onClick={() => selectCategory(category)}
              >
                <Category
                  selectedCategory={selectedCategory}
                  cat={category}
                  name={name}
                  icon={icon}
                />
              </NavLink>
            )
          })}
        </MyDiv>
      )}
    </CategoryContext.Consumer>
  )
}
