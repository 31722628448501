import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import './index.css'
import slide from '../../assets/banners/slide1.jpg'

const Ban = styled.div`
  background-image: url(${(props) => props.img || slide});
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`

export default function Banner({ images }) {
  return (
    <div className="w-full roundex-xl">
      <Carousel
        className="lg-max:h-70 h-60 roundex-xl   "
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        swipeable={true}
        emulateTouch={true}
        autoPlay={true}
        infiniteLoop={true}
        dynamicHeight={true}
        interval={5000}
      >
        {images.map((image) => (
          <Ban
            key={image}
            img={image}
            className=" bg-tertiery h-60 w-full rounded-xl"
          >
            <img />
          </Ban>
        ))}
      </Carousel>
    </div>
  )
}
