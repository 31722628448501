import { useState, useEffect } from 'react'
import pic from '../../assets/placeholder.jpg'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { data } from '../../data/Products/index'
import MyAlert from '../MyAlert'
import 'material-icons/iconfont/round.css'

const Icon = styled.div`
  background-image: url(${(props) => props.img});
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`

function SearchItems({ id, name, description, img }) {
  return (
    <Link
      to={'/stores/' + id}
      className="flex justify-center sm-max:w-full w-8/12 min-h-20 rounded-xl p-2 bg-tertiery cursor-pointer hover:bg-slate-100 shadow-xs "
    >
      <div className="w-full flex items-center gap-3">
        <div className="flex justify-center items-center w-3/12">
          <Icon
            className=" material-icons-round text-primary text-8 h-14 w-14 rounded-full bg-bg flex justify-center items-center"
            img={img}
          >
            {!img && 'store'}
          </Icon>
        </div>
        <div className="flex flex-row justify-between items-center w-9/12">
          <div className="flex flex-col ">
            <span className="text-primary-500  font-bold">{name}</span>
            <span className=" text-secondary-500">
              {description && description.substr(0, 30)}...
            </span>
          </div>
          {/* <span className="flex justify-center align-middle px-1 py-1 rounded-2  h-8 font-semibold  text-tertiery bg-primary">
            {price} $
          </span> */}
        </div>
      </div>
    </Link>
  )
}

export default function StoresSearchBar({ sendResults, stores }) {
  const [searchInput, setSearchInput] = useState('')
  // const [alert, setAlert] = useState(false)
  const [searchShow, setSearchShow] = useState(false)
  const [results, setResults] = useState([])
  // const test = [1]
  // const mydata = data

  //   useEffect(() => {
  //     searchInput.length <= 1 && emptyResults()
  //     searchInput.length > 2 && setAlert(true)
  //     searchInput.length < 2 && setAlert(false)
  //   })

  const handleChange = (e) => {
    setSearchInput(e.target.value)
    searchProduct(e.target.value)
    if (e.target.value.length < 3) {
      setSearchShow(false)
      emptyResults()
    } else {
      setSearchShow(true)
    }
    // console.log(results)
  }

  //   const handleInput = (input) => {
  //     console.log(input)
  //     joker(input)
  //     if (input.length >= 2) {
  //       searchProduct(input)
  //     } else {
  //       emptyResults()
  //     }
  //   }

  const updateResults = (res) => {
    setResults(res)
  }
  const emptyResults = () => {
    setResults([])
    sendResults([])
  }

  const sendMyResults = (data) => {
    sendResults(data)
    setSearchShow(false)
  }

  const searchProduct = (input) => {
    if(stores.length){
      const rr = stores.filter((store) => {
        return (
          store.shop_name.toLowerCase().includes(input.toLowerCase()) ||
          store.shop_description.toLowerCase().includes(input.toLowerCase())
        )
      })
      updateResults(rr)
    }else{
      if(
        stores.shop_name.toLowerCase().includes(input.toLowerCase()) ||
          stores.shop_description.toLowerCase().includes(input.toLowerCase())
      ) updateResults(stores)
    }

    // console.log(rr)
    //   const addResults = (newResults) => {
    //     setResults(() => {
    //         data.filter((product) => {
    //             return(
    //                 product.product_name.toLowerCase().includes(newResults.toLowerCase()) ||
    //                 product.product_category.toLowerCase().includes(newResults.toLowerCase())
    //             )
    //         })
    //     })
    // !results.includes(newResults) && setResults([...results, ...[newResults]])
    // console.log(results)
  }

  //   const searchProduct = (product) => {
  //     mydata.map(
  //       (prod) =>
  //         prod.product_name.toLowerCase().includes(product.toLowerCase()) &&
  //         addResults(prod)
  //     )
  //     // console.log(results)
  //   }

  return (
    <div className="fixed z-10 w-full flex  justify-center left-0 md:right-2 md:left-16  lg:left-30">
      <div className=" flex flex-col justify-center items-center gap-2 p-2 max-w-160 w-10/12 md:w-8/12  bg-bg shadow-xs  rounded-xl">
        <div className="w-full flex flex-row justify-center items-center ">
          <span className="flex justify-center items-center pl-2 rounded-l-xl font-semibold text-primary material-icons-round bg-tertiery h-9">
            search
          </span>
          <input
            className="sm-max:w-10/12 w-8/12 h-9 rounded-r-xl p-2  focus:outline-none bg-tertiery text-primary font-semibold border-1 border-primary"
            type="search"
            value={searchInput}
            placeholder="Search for a store ..."
            onChange={handleChange}
          />
        </div>

        {
          searchShow && results && (results.length > 0 ?
            results.map(
              ({ shop_id, shop_name, shop_description, shop_cover }, index) =>
                index < 5 && (
                  <SearchItems
                    key={shop_name + shop_id}
                    id={shop_id}
                    name={shop_name}
                    description={shop_description}
                    img={shop_cover}
                  />
                  )
                  ) : results.shop_name && (
                    <SearchItems
                      key={results.shop_name + results.shop_id}
                      id={results.shop_id}
                      name={results.shop_name}
                      description={results.shop_description}
                      img={results.shop_cover}
                    />
                    
            ))
          //   : alert && <MyAlert message={'no results'} />
        }
        {searchShow && results.length > 5 && (
          <div
            onClick={() => sendMyResults(results)}
            className="rounded-1 cursor-pointer flex justify-center items-center px-2 font-semibold  bg-primary text-tertiery"
          >
            see more
          </div>
        )}
        {searchShow && results.length < 1 && <MyAlert message={'no results'} />}
      </div>
    </div>
  )
}
