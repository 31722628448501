import 'material-icons/iconfont/round.css'
import styled from 'styled-components'
const StoreCover = styled.div`
  background-image: url(${(props) => props.img});
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-size: contain;
`
export default function StoreCard({ data }) {
  const assetsLink = `https://store.kwetutech.com/webservices/api/v1/images/shops/`
  return data.shop_description && (
    <div className=" md:w-120 min-h-30 flex gap-2 md:gap-5 justify-center bg-tertiery rounded-xl shadow-xs hover:bg-bg p-2 md:p-5">
      <div className="flex justify-center items-center">
        <StoreCover
          img={assetsLink + data.shop_cover}
          className="flex justify-center items-center h-20 w-20 sm:h-24 sm:w-24 md:h-30 md:w-30 bg-bg rounded-full material-icons-round text-12 sm:text-16 md:text-20 text-primary"
        >
          {data.shop_cover ? '' : 'store'}
        </StoreCover>
      </div>
      <div className="flex flex-col  gap-2 justify-center">
        <div className="flex flex-row gap-5 items-center">
          <span className="text-primary text-5  sm:text-5 md:text-7 font-bold">
            {data ? data.shop_name : 'My KwetuStore'}
          </span>
          <span className="material-icons-round text-blue-500 md-max:text-6 text-8">
            verified
          </span>
        </div>
        <span className=" text-secondary md-max:text-3 text-5">
          {data && data.shop_description
            ? data.shop_description.substr(0, 50) + '...'
            : 'Vente de trucs de malade'}
        </span>
      </div>
    </div>
  )
}
