import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'
export default function TextInput({
  label,
  loader,
  inputValue,
  handleInput,
  error,
  data,
  multiple,
}) {
  return (
    <TextField
      // error={error.state && true}
      className=" w-full"
      select={data && true}
      id={
        data
          ? 'outlined-select-currency'
          : 'outlined-basic' || (multiple && 'outlined-multiline-static')
      }
      multiline={multiple && true}
      rows={multiple}
      value={inputValue}
      label={label}
      variant="outlined"
      onChange={handleInput}
      InputProps={{ readOnly: loader && true }}
      helperText={error && error.state && error.message}
    >
      {data &&
        data.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
    </TextField>
  )
}
