import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import cover from '../../assets/placeholder.jpg'
import './index.css'
import { Link } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { useFetch } from '../../utils/hooks'
import { SessionContext } from '../../utils/Context'
import EditProduct from '../EditProduct'
import DialogBox from '../DialogBox'
import { PostHeader } from '../../pages/Login'
import { useNavigate } from 'react-router-dom'
import '../../utils/style/loader2.css'
import wht from '../../assets/whts.png'

const Slide = styled.image`
  background-image: {
    background-image: url(${(props) => props.img || cover});
    background-position: 100% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
`

const GoBack = () => {
  const handleClick = () => {
    window.history.back()
  }

  return (
    <div
      onClick={handleClick}
      className="fixed top-5 left-2 md:left-24 lg:left-64  p-2 rounded-full flex justify-center text-primary  hover:text-tertiery items-center hover:bg-primary bg-tertiery cursor-pointer z-20"
    >
      <span className="material-icons-round    font-semibold">arrow_back</span>
    </div>
  )
}


const Share = () => {

  return(
    <>
          <div class="share-dialog">
      <header>
        <h3 class="dialog-title">Share this pen</h3>
        <button class="close-button"><svg><use href="#close"></use></svg></button>
      </header>
      <div class="targets">
        <a class="button">
          <svg>
            <use href="#facebook"></use>
          </svg>
          <span>Facebook</span>
        </a>
        
        <a class="button">
          <svg>
            <use href="#twitter"></use>
          </svg>
          <span>Twitter</span>
        </a>
        
        <a class="button">
          <svg>
            <use href="#linkedin"></use>
          </svg>
          <span>LinkedIn</span>
        </a>
        
        <a class="button">
          <svg>
            <use href="#email"></use>
          </svg>
          <span>Email</span>
        </a>
      </div>
      <div class="link">
        <div class="pen-url">https://codepen.io/ayoisaiah/pen/YbNazJ</div>
        <button class="copy-link">Copy Link</button>
      </div>
    </div>

    <button class="share-button" type="button" title="Share this article">
      <svg>
        <use href="#share-icon"></use>
      </svg>
      <span>Share</span>
    </button>

    <svg class="hidden">
      <defs>
        <symbol id="share-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-share"><path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path><polyline points="16 6 12 2 8 6"></polyline><line x1="12" y1="2" x2="12" y2="15"></line></symbol>
        
        <symbol id="facebook" viewBox="0 0 24 24" fill="#3b5998" stroke="#3b5998" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></symbol>
        
        <symbol id="twitter" viewBox="0 0 24 24" fill="#1da1f2" stroke="#1da1f2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-twitter"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></symbol>
        
        <symbol id="email" viewBox="0 0 24 24" fill="#777" stroke="#fafafa" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-mail"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></symbol>
        
        <symbol id="linkedin" viewBox="0 0 24 24" fill="#0077B5" stroke="#0077B5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></symbol>
        
        <symbol id="close" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x-square"><rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect><line x1="9" y1="9" x2="15" y2="15"></line><line x1="15" y1="9" x2="9" y2="15"></line></symbol>
      </defs>
    </svg>
    </>
  )

}


export default function ProductCard({
  id,
  name,
  category,
  desc,
  price,
  state,
  photos,
  arrival,
  sponsored,
  userId,
  telephone,
}) {
  const [store, setStore] = useState()

  const [editProduct, setEditProduct] = useState(false)

  const [fetchStatut, setFetchStatut] = useState(false)
  const [errorStatut, setErrorStatut] = useState()
  const navigate = useNavigate()

  const [deleteProduct, setDeleteProduct] = useState(false)
  const [deleteLoader, setDeleteLoader] = useState(false)

  //options
  const [showOption, setShowOption] = useState(false);

  const link =
    'https://store.kwetutech.com/webservices/api/v1/images/tempfiles/'

  const [isLoading, data, error] = useFetch(
    'https://store.kwetutech.com/api/controller.php?fetch_single_shop&userId=' +
      userId
  )

  useEffect(() => {
    setStore(data)
    // console.log(data)
  }, [data])

  const handleEditProduct = () => {
    setEditProduct(!editProduct)
  }
  const handleDeleteProduct = (state) => {
    setDeleteProduct(!deleteProduct)
  }

  const deleteHandler = () => {
    console.log(id)
    setDeleteLoader(true)
    const formdata = new FormData()
    formdata.append('delProductId', id)
    postData(formdata)

    // setDeleteProduct(!deleteProduct)
  }

  const refreshHanler = () => {
    // handler()
    // setConnectionLoader(false)

    setDeleteProduct(!deleteProduct)
    setDeleteLoader(false)
    window.location.reload(false)
    // window.history.back()
  }
  const errorHanler = () => {
    // setConnectionLoader(false)
    setDeleteProduct(!deleteProduct)
    setErrorStatut()
  }

  const handleShowoption = () => {
    setShowOption(!showOption);
  }

  //post data
  const postData = (data) => {
    // console.log('posttt')
    fetch('https://store.kwetutech.com/api/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        response === 'success' && setFetchStatut(true) 
        // : console.log(response)
      })
      .catch((error) => {
        console.error('Error:', error)
        setErrorStatut({
          message:
            ' 📛 OPPS Something went wrong, please check your network and retry later',
        })
      })
    // setConnectionLoader(false)
  }


  const images = photos.filter(function (el) {
    return el != null
  })

  const shareProduct = () => {
    // console.log(10);
    if (Navigator.share) {
      Navigator.share({
        title: name,
        text : desc.substr(0, 60) + '...',
        url: 'https://store.kwetutech.com/products/' + id,
        files : [link + images[0]]
      }).then(() => {
        console.log('Thanks for sharing!');
      })
      .catch(console.error);
    } else {
      console.log('can\'t share')
    }
  }

  
  return (
    <SessionContext.Consumer>
      {({ loggedUser }) => (
        <div className="w-full">
          {editProduct && (
            <EditProduct
              handler={handleEditProduct}
              user={loggedUser}
              data={{
                product_id: id,
                product_name: name,
                product_category: category,
                product_description: desc,
                product_price: price,
                product_state: state,
              }}
            />
          )}
          <div className="w-full flex justify-center">
            <div className=" h-auto rounded-2xl bg-tertiery  w-full sm:w-120  shadow-xl ">
              <GoBack />
              <Carousel
                className="react-carousel hover:cursor-pointer bg-bg md:rounded-t-2xl"
                // showArrows={false}
                showStatus={false}
                showThumbs={false}
                swipeable={true}
                emulateTouch={true}
                autoPlay={true}
                infiniteLoop={true}
              >
                {images.map((img) => (
                  <img
                    className="h-60 sm:h-60 hover:cursor-pointer"
                    key={name + id}
                    src={link + img}
                    alt="product"
                  />
                ))}
              </Carousel>
              <div className="p-5 flex flex-col">
                <div className="flex sm-max:gap-2 flex-col items-center sm:flex-row justify-between mb-2">
                  <div className="flex sm-max:w-full sm-max: gap-2 items-center justify-between">
                    <span className="text-4 sm:text-5 text-primary-500 font-bold">
                      {name}
                    </span>
                    {sponsored && (
                      <span className=" material-icons-round text-orange-400  p-1 rounded-2 text-4 items-center justify-center flex bg-bg">
                        star
                      </span>
                    )}
                  </div>
                  <div className='flex flex-row justify-between sm-max:w-full gap-1 items-center'> 
                    <span className="flex  justify-center align-middle px-2 py-1 rounded-2  font-semibold  text-tertiery bg-primary">
                      {price} $
                    </span>
                    <span
                      onClick={shareProduct}
                       className='material-icons-round p-1 rounded-full hover:bg-primary hover:text-tertiery-500 cursor-pointer' title='Partager'> 
                        share
                    </span>
                    {/* <Share/> */}
                    {!isLoading && loggedUser && loggedUser.userId === userId && (
                      <span
                          onClick={handleShowoption}
                          className='material-icons-round p-1 rounded-full hover:bg-primary hover:text-tertiery-500 cursor-pointer' title='Partager'> 
                          more_vert
                      </span>
                    )}
                    {/* <div class="popup" onclick="myFunction()">Click me!
                     <span class="popuptext" id="myPopup">Popup text...</span>
                    </div> */}
                    {/* <div className='popup material-icons-round p-1 rounded-full hover:bg-primary hover:text-tertiery-500 cursor-pointer' title='Partager'> 
                        more_vert
                      <span class="popuptext bg-tertiery text-primary shadow-xxs flex flex-col gap-1 p-2" id="myPopup">
                          <div className='flex flex-row gap-1 justify-center text-4 items-center text-primary hover:bg-primary hover:text-tertiery-500'>
                            <span> Modifier</span>
                            <span className='material-icons-round text-5'>edit</span>
                          </div>
                          <div className='flex flex-row gap-1 justify-center text-4 items-center text-primary hover:bg-primary hover:text-tertiery-500'>
                            <span> Supprimer</span>
                            <span className='material-icons-round text-5 text-red-500'>delete</span>
                          </div>
                      </span>
                  </div>*/}
                  {(showOption && !isLoading && loggedUser && loggedUser.userId === userId) && (
                    <div className="bg-primary-500 w-36 rounded-1 px-2 py-2 shadow-xs absolute inline-block float-right mt-28 mr-3">
                          <div 
                            onClick={handleEditProduct}
                            className='flex flex-row gap-1 justify-center rounded-sm text-4 items-center text-tertiery hover:bg-tertiery hover:text-primary-500 cursor-pointer'>
                            <span> Modifier</span>
                            <span className='material-icons-round text-5'>edit</span>
                          </div>
                          <div
                            onClick={!deleteLoader && handleDeleteProduct}
                            className='flex flex-row gap-1 justify-center rounded-sm text-4 items-center text-tertiery hover:bg-tertiery hover:text-primary-500 cursor-pointer'>
                            <span> Supprimer</span>
                            <span className='material-icons-round text-5 text-red-500'>delete</span>
                          </div>
                    </div>
                  )}
                  </div>
                  
                </div>
                <span className=" text-green-500 font-bold">{state}</span>
                <div className="flex flex-col gap-2">
                  <span className="font-semibold text-secondary-500">
                    Descriptions :
                  </span>
                  <p className="font-semibold">{desc}</p>
                </div>
                <div className="flex flex-col mt-2 gap-2 w-full ">
                  <span className="font-semibold text-secondary-500">
                    Contacts :
                  </span>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={'https://wa.me/' + telephone}
                  >
                    <button className="w-full py-2 bg-primary flex justify-center items-center font-semibold text-tertiery rounded-2xl gap-2">
                      <img className='w-8' src={wht} alt="" srcset={wht} />
                      <span>{telephone}</span>
                    </button>
                  </a>
                  {loggedUser &&
                    loggedUser.userId !== userId &&
                    !isLoading &&
                    store.shop_id && (
                      <Link
                        to={'/stores/' + store.shop_id}
                        className="flex justify-center gap-1 items-center w-full py-3  border-secondary-500 border-2 font-semibold text-secondary rounded-2xl"
                      >
                        <span className="material-icons-round">store</span>{' '}
                        <span>Voir ma Boutique</span>
                      </Link>
                    )}
                  {/* {!isLoading && loggedUser && loggedUser.userId === userId && (
                    <div
                      onClick={handleEditProduct}
                      className="flex justify-center gap-1 items-center w-full py-3  border-secondary-500 border-2 font-semibold text-secondary rounded-2xl cursor-pointer hover:bg-secondary hover:text-tertiery"
                    >
                      <span className="material-icons-round">edit</span>{' '}
                      <span>Modifier le Product</span>
                    </div>
                  )}
                  {!isLoading && loggedUser && loggedUser.userId === userId && (
                    <div
                      onClick={!deleteLoader && handleDeleteProduct}
                      className="flex justify-center gap-1 items-center w-full py-3  border-red-700 border-2 font-semibold text-red-700 rounded-2xl cursor-pointer hover:bg-red-700 hover:text-tertiery"
                    >
                      <span className="material-icons-round">delete</span>{' '}
                      {deleteLoader ? (
                        <span class="loader text">&nbsp;</span>
                      ) : (
                        <span>Supprimer ce Product</span>
                      )}
                    </div>
                  )} */}
                  {fetchStatut && (
                    <DialogBox
                      state={true}
                      readOnly={true}
                      btnTitle="Ma Boutique"
                      className="w-full hidden"
                      title={'Message'}
                      content=" ✅ Votre Produit a été Supprimer avec succes."
                      button={{
                        // link: '/store',
                        title: 'Actualiser',
                        handler: refreshHanler,
                      }}
                    />
                  )}
                  {errorStatut && (
                    <DialogBox
                      state={true}
                      readOnly={true}
                      btnTitle="Ma Boutique"
                      className="w-full hidden"
                      title={'Message'}
                      content={errorStatut.message}
                      button={{
                        // link: '/store',
                        title: 'Ok',
                        handler: errorHanler,
                      }}
                    />
                  )}
                  {deleteProduct && (
                    <DialogBox
                      state={true}
                      readOnly={false}
                      btnTitle="Ma Boutique"
                      className="w-full hidden"
                      title={'Message'}
                      content={'❓ Voulez-vous Vraiment Supprimer ce Produit??'}
                      button={{
                        // link: '/store',
                        title: 'Supprimer',
                        handler: deleteHandler,
                      }}
                      reviewState={handleDeleteProduct}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </SessionContext.Consumer>
  )
}
