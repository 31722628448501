import Loader from '../../components/Loader'
import { useFetch } from '../../utils/hooks'
import { useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { stores } from '../../data/Stores'
import StoreHeader from '../../components/StoreHeader'
import { DisplayCategory } from '../../components/CategoryNavBar'
import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import EditStore from '../../components/EditStore'
import EditStoreCover from '../../components/EditStoreCover'
import { SessionContext } from '../../utils/Context'
import DialogBox from '../../components/DialogBox'

function Store() {
  const [editStoreForm, setEditStoreForm] = useState(false)
  const [editStoreCoverForm, setEditStoreCoverForm] = useState(false)
  const { id } = useParams()

  const storeId = parseInt(id)

  // const [is,dat,er] = useFetch('https://store.kwetutech.com/webservices/api/v1/products')

  const [isLoadingStore, store, errorError] = useFetch(
    'https://store.kwetutech.com/api/controller.php?fetch_shop&shop_id=' +
      storeId
  )
  const [isLoading, data, error] = useFetch(
    'https://store.kwetutech.com/api/controller.php?user_products&userId=' +
      store.userId
  )

  const link =
    'https://store.kwetutech.com/webservices/api/v1/images/tempfiles/'

  // useEffect(() => {
  //   const Store = stores.find((st) => {
  //     return st.shop_id === storeId
  //   })

  //   setStore(Store)
  // }, [])

  const showEditStoreCoverForm = () => {
    setEditStoreCoverForm(!editStoreCoverForm)
  }
  const showEditStoreForm = () => {
    setEditStoreForm(!editStoreForm)
  }

  console.log(data)

  return (
    <SessionContext.Consumer>
      {({ loggedUser, loggedUserStore }) => (
        <div className="w-full">
          {error && (
            <DialogBox
              className="hidden"
              state={true}
              title="Erreur de Connexion"
              content="Veiller verifier votre connexion internet puis reessayer"
              readOnly={true}
              // button={{
              //   title: 'Logout',
              //   handler: () => {
              //     // destroySession()
              //     // handlerLogout()
              //   },
              // }}
            />
          )}
          {isLoading ? (
            <Loader />
          ) :  store.shop_name  ?  (
            <div className="w-full">
              {' '}
              {editStoreForm && (
                <EditStore
                  handler={showEditStoreForm}
                  showEditStoreCoverHandler={showEditStoreCoverForm}
                  data={store}
                />
              )}
              {editStoreCoverForm && (
                <EditStoreCover handler={showEditStoreCoverForm} data={store} />
              )}
              <div className="flex flex-col w-full p-2 md:p-5 mb-20">
                <StoreHeader
                  showEditStoreHandeler={showEditStoreForm}
                  data={store}
                />
                <div className="w-full flex flex-col items-center gap-5 px-3 py-4">
                  <DisplayCategory
                    // onClick={loadSponsored()}
                    icon={'whatshot'}
                    name={'Produits'}
                  />
                  <div className="w-full flex flex-wrap justify-center gap-5">
                    {data && data.length > 0
                      ? data.map(
                          (
                            {
                              product_category,
                              product_id,
                              product_name,
                              product_desc,
                              product_price,
                              product_state,
                              photo1,
                              sponsored,
                            },
                            index
                          ) =>
                            index < 9 && (
                              <Link
                                className=""
                                key={product_name + product_id}
                                to={'/products/' + product_id}
                              >
                                <Card
                                  image={link + photo1}
                                  name={product_name}
                                  price={product_price}
                                  desc={product_desc}
                                  sponsored={sponsored}
                                  state={product_state}
                                />
                              </Link>
                            )
                        )
                      : data.product_name && (
                          <Link
                            className=""
                            key={data.product_name + data.product_id}
                            to={'/products/' + data.product_id}
                          >
                            <Card
                              image={link + data.photo1}
                              name={data.product_name}
                              price={data.product_price}
                              desc={data.product_desc}
                              sponsored={data.sponsored}
                              state={data.product_state}
                            />
                          </Link>
                        )}
                  </div>
                </div>
              </div>
            </div>
          )
           :
           <DialogBox
              className="hidden"
              state={true}
              title="Boutique Indisponible"
              content="Cette Boutique n'existe pas ou elle est actuellement Fermée"
              readOnly={true}
              button={{
                title: 'Revenir en Arrière',
                handler: () => {
                  window.history.back()
                  // handlerLogout()
                },
              }}
            />
        }
        </div>
      )}
    </SessionContext.Consumer>
  )
}

export default Store
