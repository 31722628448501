import '../../utils/style/loader.css'
import logo from '../../assets/logo.png'
import styled from 'styled-components'
const Logo = styled.div`
  background-image: {
    background-image: url(${logo});
    background-position: ceneter;
    background-repeat: no-repeat;
    background-size: contain;
  }
`

export default function Loader() {
  return (
    <div className="fixed left-0 top-0 flex flex-col justify-center items-center bg-slate-200  h-screen w-screen md-max:h-screen md-max:w-screen z-10 gap-2">
      <Logo className="h-20 w-20">
        <img src={logo} className="" alt="" srcSet="" />
      </Logo>
      <div className=" lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
