import { useParams } from 'react-router-dom'
import { useFetch } from '../../utils/hooks'
import Loader from '../../components/Loader'
import { useEffect } from 'react'
import DialogBox from '../../components/DialogBox'

import ProductCard from '../../components/ProductCard'

export default function Product() {
  const { id } = useParams()
  const productId = parseInt(id)
  const [isLoading, data, error] = useFetch(
    'https://store.kwetutech.com/webservices/api/v1/product/' + productId
  )

  useEffect(() => {
    // console.log(data)
  }, [data])

  // console.log('https://store.kwetutech.com/webservices/api/v1/product/' + id)

  return (
    <div className="flex justify-center w-full md-max:mb-20 h-full md:p-5">
      {error && (
        <DialogBox
          className="hidden"
          state={true}
          title="Erreur de Connexion"
          content="Veiller verifier votre connexion internet puis reessayer"
          readOnly={true}
          // button={{
          //   title: 'Logout',
          //   handler: () => {
          //     // destroySession()
          //     // handlerLogout()
          //   },
          // }}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        data && (
          <ProductCard
            key={data.product_name + data.product_id}
            id={data.product_id}
            name={data.product_name}
            category={data.product_category}
            desc={data.product_desc}
            price={data.product_price}
            photos={[data.photo1, data.photo2, data.photo3, data.photo4]}
            state={data.product_state}
            sponsored={data.sponsored}
            arrival={data.new_arrival}
            userId={data.userId}
            telephone={data.telephone}
          />
        )
      )}
    </div>
  )
}
