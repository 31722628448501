import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { FormHelperText } from '@mui/material'

export default function PasswordInput({
  label,
  loader,
  handleInput,
  inputValue,
  error,
}) {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }
  return (
    <FormControl className="w-full" sx={{}} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        // error={error.state && true}
        className="w-full"
        id="outlined-adornment-password"
        value={inputValue}
        onChange={handleInput}
        type={showPassword ? 'text' : 'password'}
        // helperText={error && error.state && error.message}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
        readOnly={loader && true}
      />
      {error && error.state && <FormHelperText>{error.message}</FormHelperText>}
    </FormControl>
  )
}
