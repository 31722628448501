/* eslint-disable react-hooks/exhaustive-deps */
// import styled from '@emotion/styled'
import { Link, Navigate } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'

// import { banners } from '../../data/Banners'
import LoadingBtn from '../../components/LoadingBtn'
import TextInput from '../../components/TextInput'
import { PostHeader } from '../Login'
import DialogBox from '../../components/DialogBox'
import EditPassword from '../../components/EditPassword'

export default function RecoverPassword({handler}) {
  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)
  // console.log(email)
  //fetch states
  const [fetchStatut, setFetchStatut] = useState(false)
  const [errorStatut, setErrorStatut] = useState()

  //input value States

  const [telephoneValue, setTelephoneValue] = useState('')

  //cove Value
  const [codeValue, setCodeValue] = useState('')

  //filled input States

  const [filledTelephone, setFilledTelephone] = useState(false)

  //recovery code
  const [filledCode, setFilledCode] = useState(false)

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  //error
  const [telephoneError, setTelephoneError] = useState({})

  const [codeError, setCodeError] = useState({})

  //emailsent Status
  const [emailSentStatus, setEmailSentStatus] = useState(false)

  //editPasswordState
  const [editPasswordForm, setEditPasswordForm] = useState(false)

  //userId
  const [userId, setUserId] = useState()


  //Changes Handleres

  const handleSeConnecter = () => {
    handler()
    console.log(10)
  }

  const handleTelephoneChange = (e) => {
    setTelephoneValue(e.target.value)
    checkTelephone()
  }

  const handleCodeChange = (e) => {
    setCodeValue(e.target.value)
    checkCode()
  }


   //Mail REGX
   const checkTelephone = useCallback(() => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (telephoneValue.match(mailformat)) {
      // setFilledEmail(true)
      setFilledTelephone(true)
      CheckInputs()
    } else {
      // setFilledEmail(false)
      setFilledTelephone(false)
    }
  })

   //Mail REGX
   const checkCode = useCallback(() => {
    // const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (codeValue.length > 3) {
      setFilledCode(true)
      CheckInputs()
    } else {
      // setFilledEmail(false)
      setFilledCode(false)
    }
  })


  //Inputs General Check
  const CheckInputs = () => {
    if(emailSentStatus){
      if (filledTelephone === true) {
        handleChecks(true)
      } else {
        // console.log(1234)
        handleChecks(false)
      }
    }else{
      if (filledCode === true) {
        handleChecks(true)
      } else {
        // console.log(1234)
        handleChecks(false)
      }
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  const handleConnectionClick = () => {
    setConnectionLoader(true)

    // console.log(telephoneValue)
    // setEmailSentStatus(true)
    // setConnectionLoader(false)

    //formData
    setConnectionLoader(true)
    const formdata = new FormData()
    formdata.append('recovery_code', 'reverify_email')
    formdata.append('email', telephoneValue)
    // formdata.append('code', telephoneValue)

    postData(formdata)
  }

  const handleSendCode = () => {
    //formData
    setConnectionLoader(true)
    const formdata = new FormData()
    formdata.append('reverify_email', 'reverify_email')
    formdata.append('email', telephoneValue)
    formdata.append('code', codeValue)
    
    postData(formdata)
  }

  const handleResendCode = () => {
    setConnectionLoader(true)

    //formData
    setConnectionLoader(true)
    const formdata = new FormData()
    formdata.append('resend_verification_email', 'resend_verification_email')
    // formdata.append('email', email.email.email)
    postData(formdata)
  }

  const refreshHanler = () => {
    setConnectionLoader(false)
    setFetchStatut(false)
    window.location.reload(false)
  }


  const errorHanler = () => {
    setConnectionLoader(false)
    setErrorStatut()
  }

  //post data
  const postData = (data) => {
    // console.log('posttt')
    fetch('https://store.kwetutech.com/api/authentication/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response)
        if (response === 'true') {
          console.log(response)
          setEmailSentStatus(true)
          setConnectionLoader(false)
        } else {
          if(response.status){
            // console.log(response)
            setUserId(response.email)
            setEditPasswordForm(true)
            setConnectionLoader(false)

          }else{
          setErrorStatut({
            message: response === 'failed' ? 'Code incorrect' : response,
          })
          }
        }
        // setConnectionLoader(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setErrorStatut({
          message:
            'OPPS Something went wrong, please check your network and retry later',
        })
      })
    // setConnectionLoader(false)
  }

  useEffect(() => {
    emailSentStatus ? checkCode() :checkTelephone()
    CheckInputs()
  }, [telephoneValue,codeValue, inputChecked])

  const testIt = () => {
    console.log(filledTelephone)
  }

  return (
    <div className="md:p-5 max-w-125 w-full flex flex-col justify-center items-center gap-3">
      {/* {!email && <Navigate to="/login" replace={true} />} */}
      {editPasswordForm ? <EditPassword noBorder={true} userId={userId}/> :
      (
        <div className='flex flex-col justify-center items-center gap-3'>
          <span className="font-semibold text-8 text-primary ">
            Password Recovery
          </span> 
          <span className=" text-4 text-center text-primary ">
            {emailSentStatus ? "Un Code a été envoyé à votre mail " + telephoneValue + " Veillez le saisir pour finaliser la récuperation de votre compte. " : "Veillez entrer votre email pour proceder à la récuperation de votre compte"}
            {/* <span className="font-semibold">{email.email.email + ' '}</span> pour */}
            {/* verifier votre compte */}
          </span>
          <form className="w-full flex flex-col justify-center gap-3">
            {emailSentStatus ? 
              <TextInput
                label="Code de Confirmation"
                loader={connectionLoader}
                inputValue={codeValue}
                handleInput={handleCodeChange}
              /> : 
              <TextInput
                 label="Email"
                 loader={connectionLoader}
                 inputValue={telephoneValue}
                 handleInput={handleTelephoneChange}
              />
             }
            <LoadingBtn
              text={emailSentStatus ? "Envoyer le code" : "VERIFIER"}
              loader={connectionLoader}
              checked={ emailSentStatus ? filledCode : filledTelephone}
              handleConnectionClick={emailSentStatus ? handleSendCode : handleConnectionClick }
            />
            {emailSentStatus && (
              <LoadingBtn
              text="Renvoyer le code"
              loader={connectionLoader}
              checked={true}
              handleConnectionClick={handleResendCode}
              variant="text"
            />
            )}
            <LoadingBtn
              text="Se connecter"
              loader={connectionLoader}
              checked={true}
              handleConnectionClick={handleSeConnecter}
              variant="text"
            />
          </form>
        </div>
      )
      }
      {errorStatut && (
        <DialogBox
          state={true}
          readOnly={true}
          btnTitle="Ma Boutique"
          className="w-full hidden"
          title={'Message'}
          content={errorStatut.message}
          button={{
            // link: '/store',
            title: 'Ok',
            handler: errorHanler,
          }}
        />
      )}
    </div>
  )
}
