/* eslint-disable react-hooks/exhaustive-deps */
import 'material-icons/iconfont/round.css'
import { useState, useEffect, useCallback } from 'react'
import PasswordInput from '../../components/PasswordInput'
import PerfectScrollbar from 'react-perfect-scrollbar'
import LoadingBtn from '../LoadingBtn'
import { PostHeader } from '../../pages/Login'
import { StoreSession } from '../../utils/Context'
import DialogBox from '../../components/DialogBox'


export default function EditPassword({ user, handler,noBorder,userId }) {
  //finale data
  const [product, setProduct] = useState({})

  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)

  //input value States
  const [passwordValue, setPasswordValue] = useState('')
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('')

  //filled input States
  const [filledPassword, setFilledPassword] = useState(false)
  const [filledConfirmPassword, setFilledConfirmPassword] = useState(false)

  //error states
  const [usernameError, setUsernameError] = useState({})
  const [passwordError, setPasswordError] = useState({})

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  //errors
  const [errorStatut, setErrorStatut] = useState()


  //error handler
  const handleUsernameError = (eState = false, eMessage = '') => {
    eState
      ? setUsernameError({ state: eState, message: '* ' + eMessage })
      : setUsernameError({})
  }
  const handlePasswordError = (eState = false, eMessage = '') => {
    eState
      ? setPasswordError({ state: eState, message: '* ' + eMessage })
      : setPasswordError({})
  }

  //Changes Handleres
  const handlePasswordChange = (e) => {
    setPasswordValue(e.target.value)
    checkPassword()
  }
  const handleConfirmPasswordChange = (e) => {
    setConfirmPasswordValue(e.target.value)
    checkConfirmPassword()
  }

  //Check handlers
  const checkPassword = useCallback(() => {
    // console.log(80)
    if (passwordValue.length >= 8) {
      // console.log(8)
      setFilledPassword(true)
      handlePasswordError()
      CheckInputs()
    } else {
      setFilledPassword(false)
      handlePasswordError(true, 'Password Must have more than 8 characters')
    }
  })
  const checkConfirmPassword = useCallback(() => {
    if (
      confirmPasswordValue.length >= 8 &&
      confirmPasswordValue === passwordValue
    ) {
      setFilledConfirmPassword(true)
      CheckInputs()
    } else {
      setFilledConfirmPassword(false)
    }
  })
  //Inputs General Check
  const CheckInputs = () => {
    if (filledPassword === true && filledConfirmPassword === true) {
      handleChecks(true)
    } else {
      // console.log(1234)
      handleChecks(false)
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  const handleConnectionClick = () => {

    // formData
    setConnectionLoader(true)
    const formdata = new FormData()
    // formdata.append('verification', 'rupdate')
    formdata.append('new_password', passwordValue)
    user && formdata.append('id', user.email)
    userId &&  formdata.append('id', userId)
    postData(formdata)
  }

  const postData = (data) => {
    // console.log('posttt')
    fetch('https://store.kwetutech.com/api/authentication/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        if (response === 1) {
          // StoreSession(response)
          // setConnectionLoader(false)
          setErrorStatut({
            message: 'Votre Mot de Passe a été modifié avec succèss',
            action: 'reload',
          })
          // setFetchStatut(true)
        } else {
          setErrorStatut({
            message: response === 0 ? 'Opps error while upadating your password' : response,
            
          })
        }
        // setConnectionLoader(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setErrorStatut({
          message:
            'OPPS Something went wrong, please check your network and retry later',
        })
      })
    // setConnectionLoader(false)
  }

  //error handler
  
  const errorHanler = () => {
    setConnectionLoader(false)
    if(errorStatut.action && errorStatut.action === 'reload') window.location.reload = false
    setErrorStatut()
    
  }


  //effect listener
  useEffect(() => {
    checkPassword()
    checkConfirmPassword()
  }, [handlePasswordChange,passwordValue, confirmPasswordValue])

  const testIt = () => {
    //   console.log(filledUsername)
    //   console.log(filledPassword)
  }

  return (
    noBorder ? (
      <PerfectScrollbar className="h-full w-full md-max:mb-10 md-max:overflow-y-scroll">
      <div className="h-10 flex justify-center items-center">
        <span className="ml-5 md:text-5 font font-semibold text-primary">
        Mettre à jour le Mot de Passe 
        </span>
        {/* <button
          onClick={handler}
          className="material-icons-round text-10 font-semibold text-primary hover:text-red-500"
        >
          close
        </button> */}
      </div>

      <div className="flex flex-col gap-5  p-2 md:">
        <div className=" w-full flex flex-col md:flex-row  gap-3">
          <PasswordInput
            label="New Password"
            loader={connectionLoader}
            inputValue={passwordValue}
            handleInput={handlePasswordChange}
            error={passwordError}
          />
        </div>
        <div className=" w-full flex flex-col md:flex-row  gap-3">
          <PasswordInput
            label="Confirm Password"
            loader={connectionLoader}
            inputValue={confirmPasswordValue}
            handleInput={handleConfirmPasswordChange}
            error={passwordError}
          />
        </div>
      </div>

      <div className="w-full flex justify-center p-2">
        <LoadingBtn
          text="Enregistrer"
          loader={connectionLoader}
          checked={inputChecked}
          handleConnectionClick={handleConnectionClick}
        />
      </div>
      {/* <button onClick={testIt}>text</button> */}
      {errorStatut && (
        <DialogBox
          state={true}
          readOnly={true}
          btnTitle="Ma Boutique"
          className="w-full hidden"
          title={'Message'}
          content={errorStatut.message}
          button={{
            // link: '/store',
            title: 'Ok',
            handler: errorHanler,
          }}
        />
      )}
    </PerfectScrollbar>

    ) : (
    <div className="fixed flex justify-center items-center md-max:h-full md:min-h-full w-full bg-bg bg-opacity-90 pb-5 ">
      <div className="flex justify-center items-center h-full w-full md:mr-30 lg:mr-68 p-2 md:p-5 ">
        <div className="bg-tertiery flex flex-col h-full w-full rounded-xl shadow-xs p-2 md:p-5">
          <PerfectScrollbar className="h-full w-full md-max:mb-10 md-max:overflow-y-scroll">
            <div className="h-10 flex justify-between items-center">
              <span className="ml-5 md:text-6 font font-semibold text-primary">
                Modifier Le Mot de Passe
              </span>
              <button
                onClick={handler}
                className="material-icons-round text-10 font-semibold text-primary hover:text-red-500"
              >
                close
              </button>
            </div>

            <div className="flex flex-col gap-5  p-2 md:">
              <div className=" w-full flex flex-col md:flex-row  gap-3">
                <PasswordInput
                  label="Password"
                  loader={connectionLoader}
                  inputValue={passwordValue}
                  handleInput={handlePasswordChange}
                  error={passwordError}
                />
              </div>
              <div className=" w-full flex flex-col md:flex-row  gap-3">
                <PasswordInput
                  label="Confirm Password"
                  loader={connectionLoader}
                  inputValue={confirmPasswordValue}
                  handleInput={handleConfirmPasswordChange}
                  error={passwordError}
                />
              </div>
            </div>

            <div className="w-full flex justify-center p-2">
              <LoadingBtn
                text="Enregistrer"
                loader={connectionLoader}
                checked={inputChecked}
                handleConnectionClick={handleConnectionClick}
              />
            </div>
            {/* <button onClick={testIt}>text</button> */}
          </PerfectScrollbar>
        </div>
      </div>
      {errorStatut && (
        <DialogBox
          state={true}
          readOnly={true}
          btnTitle="Ma Boutique"
          className="w-full hidden"
          title={'Message'}
          content={errorStatut.message}
          button={{
            // link: '/store',
            title: 'Ok',
            handler: errorHanler,
          }}
        />
      )}
    </div>)
  )
}
