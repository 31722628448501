import LoadingButton from '@mui/lab/LoadingButton'
export default function LoadingBtn({
  text,
  loader,
  handleConnectionClick,
  checked,
  variant,
}) {
  // console.log(checked)
  return (
    <LoadingButton
      className="w-full"
      disabled={!checked && true}
      loading={loader}
      onClick={handleConnectionClick}
      // loadingPosition="start"
      // startIcon={<SaveIcon />}
      variant={variant ? variant : 'contained'}
    >
      {text}
    </LoadingButton>
  )
}
