/* eslint-disable react-hooks/exhaustive-deps */
import 'material-icons/iconfont/round.css'
import { useState, useEffect, useCallback } from 'react'
import { shortCategories } from '../../data/Products/Categories'
import TextInput from '../TextInput'
import ImageUpload from '../ImageUpload'
import PerfectScrollbar from 'react-perfect-scrollbar'
import LoadingBtn from '../LoadingBtn'
import { storeAssetsLink } from '../StoreHeader'
import { PostHeader } from '../../pages/Login'
import { StoreSessionUserStore } from '../../utils/Context'
import DialogBox from '../DialogBox'
export default function EditStoreCover({ handler, data }) {
  //file data
  // const [product, setProduct] = useState({})

  const [fetchStatut, setFetchStatut] = useState(false)

  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)

  //input value States
  // const [storeNameValue, setStoreNameValue] = useState('')
  // const [storeDescriptionValue, setProductDescriptionlValue] = useState('')
  // const [storeAddressValue, setStoreAddressValue] = useState('')
  // const [storeTelephoneValue, setStoreTelephoneValue] = useState('')

  // images state
  const [image1, setImage1] = useState('')

  useEffect(() => {
    if (data) {
      // setStoreNameValue(data.shop_name)
      // setProductDescriptionlValue(data.shop_description)
      // setStoreAddressValue(data.shop_address)
      // setStoreTelephoneValue(data.shop_contacts)
      setImage1({ link: storeAssetsLink + data.shop_cover })
    }
  }, [])

  //filled input States
  // const [filledStoreName, setFilledStoreName] = useState(false)
  // const [filledStoreDescription, setFilledStoreDescription] = useState(false)
  // const [filledStoreAddress, setFilledStoreAddress] = useState(false)
  // const [filledStoreTelephone, setFilledStoreTelephone] = useState(false)
  const [filledImage1, setFilledImage1] = useState(false)

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  //Changes Handleres
  // const handleStoreNameChange = (e) => {
  //   setStoreNameValue(e.target.value)
  //   // console.log(storeNameValue)
  //   checkStoreName()
  // }
  // const handleStoreDescriptionlChange = (e) => {
  //   setProductDescriptionlValue(e.target.value)
  //   checkStoreDescription()
  // }
  // const handleStoreAddressChange = (e) => {
  //   setStoreAddressValue(e.target.value)
  //   checkStoreAddress()
  // }
  // const handleStoreTelephoneChange = (e) => {
  //   setStoreTelephoneValue(e.target.value)
  //   checkStoreTelephone()
  // }

  const handleImage1Upload = (newImg) => {
    // console.log('newImg')
    setImage1(newImg)
    setFilledImage1(true)
    CheckInputs()
  }
  const handleImage1Delete = (newImg) => {
    // console.log('delete')
    setImage1('')
    setFilledImage1(false)
    CheckInputs()
  }

  // //Check handlers
  // const checkStoreName = useCallback(() => {
  //   if (storeNameValue.length >= 5) {
  //     setFilledStoreName(true)
  //     CheckInputs()
  //   } else {
  //     setFilledStoreName(false)
  //   }
  // })
  // const checkStoreDescription = useCallback(() => {
  //   if (storeDescriptionValue.length >= 6) {
  //     setFilledStoreDescription(true)
  //     CheckInputs()
  //   } else {
  //     setFilledStoreName(false)
  //   }
  // })
  // const checkStoreAddress = useCallback(() => {
  //   if (storeAddressValue.length >= 1) {
  //     setFilledStoreAddress(true)
  //     CheckInputs()
  //   } else {
  //     setFilledStoreAddress(false)
  //   }
  // })
  // const checkStoreTelephone = useCallback(() => {
  //   if (storeTelephoneValue.length >= 10) {
  //     setFilledStoreTelephone(true)
  //     CheckInputs()
  //   } else {
  //     setFilledStoreTelephone(false)
  //   }
  // })

  //Inputs General Check
  const CheckInputs = () => {
    if (
      // filledStoreName === true &&
      // filledStoreDescription === true &&
      // filledStoreAddress === true &&
      // filledStoreTelephone === true
      // // &&
      filledImage1
    ) {
      handleChecks(true)
      // console.log(usernameValue)
      // console.log(passwordValue)
    } else {
      // console.log(1234)
      handleChecks(false)
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  const handleConnectionClick = () => {
    setConnectionLoader(true)

    const formdata = new FormData()
    formdata.append('update_a_shop_cover', 'update_a_shop_cover')
    // formdata.append('shop_name', storeNameValue)
    // formdata.append('shop_status', 'open')
    // formdata.append('shop_address', storeAddressValue)
    // formdata.append('shop_description', storeDescriptionValue)
    // formdata.append('shop_contacts', storeTelephoneValue)
    formdata.append('shop_cover', image1.file[0], image1.fileName)
    formdata.append('userId', data.userId)
    console.log(image1)
    // setConnectionLoader(false)
    postData(formdata)
  }

  //refresh handler

  const refreshHanler = () => {
    handler()
    setConnectionLoader(false)
    window.location.reload(false)
  }

  //post dada

  const postData = (data) => {
    fetch('https://store.kwetutech.com/api/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        response === 'success'
          ? fetch(
              'https://store.kwetutech.com/api/controller.php?fetch_single_shop&userId=' +
                data.userId
            )
              .then((res) => res.json())
              .then((res) => {
                res[0] && StoreSessionUserStore(res[0])
                setFetchStatut(true)
                // setConnectionLoader(false)
              })
              .catch((error) => {
                console.error('Error:', error)
              })
          : console.log(response)
        // window.location.reload(false)
        // setConnectionLoader(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setConnectionLoader(false)
      })
  }

  //effect listener
  useEffect(() => {
    // checkStoreName()
    CheckInputs()
    // checkStoreDescription()
    // checkStoreAddress()
    // checkStoreTelephone()
  }, [
    // filledStoreName,
    // filledStoreDescription,
    // filledStoreAddress,
    // filledStoreTelephone,
    filledImage1,
  ])

  const testIt = () => {
    // console.log(filledStoreName)
    // console.log(filledStoreDescription)
    // console.log(filledStoreAddress)
    // console.log(filledStoreTelephone)
    console.log(filledImage1)
  }

  return (
    <div className="fixed flex justify-center items-center md-max:h-full md:min-h-full w-full bg-bg bg-opacity-90 pb-5 ">
      <div className="flex justify-center items-center h-full w-full md:mr-30 lg:mr-68 p-2 md:p-5 ">
        <div className="bg-tertiery flex flex-col  rounded-xl shadow-xs p-2 md:p-5">
          <PerfectScrollbar className="h-full w-full md-max:mb-10 md-max:overflow-y-scroll">
            <div className="h-10 flex justify-between items-center">
              <span className="ml-5 md:text-6 font font-semibold text-primary">
                Modifier La Photo de Couverture
              </span>
              <button
                onClick={handler}
                className="material-icons-round text-10 font-semibold text-primary hover:text-red-500"
              >
                close
              </button>
            </div>

            <div className="w-full flex flex-col md:flex-row gap-5 justify-center items-center ">
              <div className="flex flex-row gap-5  flex-wrap p-2 md:">
                <ImageUpload
                  className={' h-40 w-42 md:h-80 md:w-85'}
                  loader={connectionLoader}
                  file={image1}
                  handleImagesUpload={handleImage1Upload}
                  handleImagesDelete={handleImage1Delete}
                />
              </div>
            </div>
            <div className="w-full flex justify-center p-2">
              <LoadingBtn
                text="Enregistrer"
                loader={connectionLoader}
                checked={inputChecked}
                handleConnectionClick={handleConnectionClick}
              />
            </div>
            {/* <button onClick={testIt}>text</button> */}
          </PerfectScrollbar>
          {fetchStatut && (
            <DialogBox
              state={true}
              readOnly={true}
              btnTitle="Ma Boutique"
              className="w-full hidden"
              title={'Message'}
              content="Votre Photo a été mis a jour avec succes."
              button={{
                // link: '/store',
                title: 'Actualiser',
                handler: refreshHanler,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
