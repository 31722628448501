import 'material-icons/iconfont/round.css'
import { Link } from 'react-router-dom'
import DialogBox from '../DialogBox'

export default function ProfileHeader({
  user,
  store,
  showPostHandler,
  showEditProfileHandler,
  showCreateStoreHandler,
}) {
  return (
    <div className="flex w-full min-w-fit  gap-2 md:gap-5 flex-col md:flex-row">
      <div className="w-full min-h-40 flex gap-2 md:gap-5 justify-center bg-tertiery rounded-xl shadow-sm p-2 md:p-5">
        <div className="flex justify-center items-center">
          <div className="flex justify-center items-center h-20 w-20 sm:h-24 sm:w-24 md:h-30 md:w-30 bg-bg rounded-full material-icons-round text-12 sm:text-16 md:text-20 text-primary">
            person
          </div>
        </div>
        <div className="flex flex-col  gap-2 justify-center">
          <div className="flex flex-row gap-5 items-center">
            <span className="text-primary text-5  sm:text-6 md:text-8 font-bold">
              {user ? user.names : 'Abraham Mulindi Tommy'}
            </span>
          </div>
          <div className="flex flex-col md:flex-row gap-2 md:gap-5">
            <div className="flex gap-1 justify-center items-center bg-tertiery text-center text-secondary font-semibold sm-max:px-2 sm-max:text-3 px-3 py-1 rounded-2 cursor-pointer shadow-xxs">
              <span className="material-icons-round text-4">call</span>
              <span className="">
                {user ? user.telephone : '+243991097363'}
              </span>
            </div>
            <div
              onClick={showEditProfileHandler}
              className="flex gap-1 justify-center items-center bg-tertiery text-center text-secondary font-semibold sm-max:px-2 sm-max:text-3 px-3 py-1 rounded-2 cursor-pointer hover:bg-primary hover:text-tertiery shadow-xxs"
            >
              <span className="material-icons-round text-4">edit</span>
              <span className="">Edit Profile</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full md:w-4/12 justify-center gap-2 bg-tertiery-500 shadow-xs p-5 md:p-5 rounded-xl">
        <div
          onClick={showPostHandler}
          className="flex gap-1 justify-center items-center bg-green-500 text-center text-tertiery font-semibold sm-max:px-2 sm-max:text-3 px-3 py-2 rounded-2 cursor-pointer hover:bg-primary "
        >
          <span className="material-icons-round text-4">camera_alt</span>
          <span>Ajouter</span>
        </div>
        {store ? (
          <Link
            to={store && '/mystore/' + store.shop_id}
            className="flex gap-1 justify-center items-center bg-secondary text-center text-tertiery font-semibold sm-max:px-2 sm-max:text-3 px-3 py-2 rounded-2 cursor-pointer hover:bg-primary"
          >
            <span className="material-icons-round text-4">store</span>
            <span>Ma Boutique</span>
          </Link>
        ) : (
          <DialogBox
            // state={true}
            btnTitle="Ma Boutique"
            className="w-full"
            title={'Message'}
            content="Vous n'avez pas encore de boutique, voulez-vous en Creer ??"
            button={{
              // link: '/store',
              title: 'Creer',
              handler: showCreateStoreHandler,
            }}
          />
        )}
      </div>
    </div>
  )
}
