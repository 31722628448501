/* eslint-disable react-hooks/exhaustive-deps */
import 'material-icons/iconfont/round.css'
import { useState, useEffect, useCallback } from 'react'

import TextInput from '../TextInput'
import ImageUpload from '../ImageUpload'
import PerfectScrollbar from 'react-perfect-scrollbar'
import LoadingBtn from '../LoadingBtn'
import { PostHeader } from '../../pages/Login'
import DialogBox from '../DialogBox'
import { StoreSessionUserStore } from '../../utils/Context'

export default function CreateStore({ handler, user }) {
  //file data
  const [product, setProduct] = useState({})

  const [fetchStatut, setFetchStatut] = useState(false)

  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)

  //input value States
  const [storeNameValue, setStoreNameValue] = useState('')
  const [storeDescriptionValue, setProductDescriptionlValue] = useState('')
  const [storeAddressValue, setstoreAddressValue] = useState('')
  const [storeTelephoneValue, setstoreTelephoneValue] = useState('')

  //filled input States
  const [filledStoreName, setFilledStoreName] = useState(false)
  const [filledStoreDescription, setFilledStoreDescription] = useState(false)
  const [filledStoreAddress, setFilledStoreAddress] = useState(false)
  const [filledStoreTelephone, setFilledStoreTelephone] = useState(false)
  const [filledImage1, setFilledImage1] = useState(false)

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  // images state
  const [image1, setImage1] = useState('')

  //Changes Handleres
  const handleStoreNameChange = (e) => {
    setStoreNameValue(e.target.value)
    // console.log(storeNameValue)
    checkStoreName()
  }
  const handleStoreDescriptionlChange = (e) => {
    setProductDescriptionlValue(e.target.value)
    checkStoreDescription()
  }
  const handleStoreAddressChange = (e) => {
    setstoreAddressValue(e.target.value)
    checkStoreAddress()
  }
  const handleStoreTelephoneChange = (e) => {
    setstoreTelephoneValue(e.target.value)
    checkStoreTelephone()
  }

  const handleImage1Upload = (newImg) => {
    // console.log('newImg')
    setImage1(newImg)
    setFilledImage1(true)
    CheckInputs()
  }
  const handleImage1Delete = (newImg) => {
    // console.log('delete')
    setImage1('')
    setFilledImage1(false)
    CheckInputs()
  }

  //Check handlers
  const checkStoreName = useCallback(() => {
    if (storeNameValue.length >= 5) {
      setFilledStoreName(true)
      CheckInputs()
    } else {
      setFilledStoreName(false)
    }
  })
  const checkStoreDescription = useCallback(() => {
    if (storeDescriptionValue.length >= 6) {
      setFilledStoreDescription(true)
      CheckInputs()
    } else {
      setFilledStoreName(false)
    }
  })
  const checkStoreAddress = useCallback(() => {
    if (storeAddressValue.length >= 1) {
      setFilledStoreAddress(true)
      CheckInputs()
    } else {
      setFilledStoreAddress(false)
    }
  })
  const checkStoreTelephone = useCallback(() => {
    if (storeTelephoneValue.length >= 10) {
      setFilledStoreTelephone(true)
      CheckInputs()
    } else {
      setFilledStoreTelephone(false)
    }
  })

  //Inputs General Check
  const CheckInputs = () => {
    if (
      filledStoreName === true &&
      filledStoreDescription === true &&
      filledStoreAddress === true &&
      filledStoreTelephone === true &&
      filledImage1
    ) {
      handleChecks(true)
      // console.log(usernameValue)
      // console.log(passwordValue)
    } else {
      // console.log(1234)
      handleChecks(false)
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  const handleConnectionClick = () => {
    setConnectionLoader(true)
    console.log(image1.link)
    //api call
    const formdata = new FormData()
    formdata.append('create_a_shop', 'create_a_shop')
    formdata.append('shopName', storeNameValue)
    formdata.append('shopAddress', storeAddressValue)
    formdata.append('shopDescription', storeDescriptionValue)
    formdata.append('shopContacts', storeTelephoneValue)
    formdata.append('shopCover', image1.file[0], image1.fileName)
    formdata.append('userId', user.userId)
    // console.log(storeTelephoneValue)
    postData(formdata)
    // setFetchStatut(true)
    // console.log(10101)
    // setConnectionLoader(false)
  }

  // useEffect(() => {
  // }, [fetchStatut])

  const refreshHanler = () => {
    handler()
    setConnectionLoader(false)
    window.location.reload(false)
  }

  //post dada

  const postData = (data) => {
    fetch('https://store.kwetutech.com/api/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(response)
        fetch(
          'https://store.kwetutech.com/api/controller.php?fetch_single_shop&userId=' +
            user.userId
        )
          .then((res) => res.json())
          .then((res) => {
            res[0] && StoreSessionUserStore(res[0])
            setFetchStatut(true)
            // setConnectionLoader(false)
          })
          .catch((error) => {
            console.error('Error:', error)
          })
        // window.location.reload(false)
        // setConnectionLoader(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setConnectionLoader(false)
      })
  }

  //effect listener
  useEffect(() => {
    checkStoreName()
    checkStoreDescription()
    checkStoreAddress()
    checkStoreTelephone()
  }, [
    filledStoreName,
    filledStoreDescription,
    filledStoreAddress,
    filledStoreTelephone,
    filledImage1,
  ])

  const testIt = () => {
    console.log(filledStoreName)
    console.log(filledStoreDescription)
    console.log(filledStoreAddress)
    console.log(filledStoreTelephone)
    console.log(filledImage1)
  }

  return (
    <div className="fixed flex justify-center items-center md-max:h-full md:min-h-full w-full bg-bg bg-opacity-90 pb-5 ">
      <div className="flex justify-center items-center h-full w-full md:mr-30 lg:mr-68 p-2 md:p-5 ">
        <div className="bg-tertiery flex flex-col h-full w-full rounded-xl shadow-xs p-2 md:p-5">
          <PerfectScrollbar className="h-full w-full md-max:mb-10 md-max:overflow-y-scroll">
            <div className="h-10 flex justify-between items-center">
              <span className="ml-5 md:text-6 font font-semibold text-primary">
                Creer Ma Boutique
              </span>
              <button
                onClick={handler}
                className="material-icons-round text-10 font-semibold text-primary hover:text-red-500"
              >
                close
              </button>
            </div>

            <div className="w-full flex flex-col md:flex-row gap-5 justify-center items-center ">
              <div className="flex flex-row gap-5  flex-wrap p-2 md:">
                <ImageUpload
                  className={'h-40 w-42'}
                  loader={connectionLoader}
                  file={image1}
                  name="shopCover"
                  handleImagesUpload={handleImage1Upload}
                  handleImagesDelete={handleImage1Delete}
                />
              </div>
              <div className="w-full md:w-4/6 flex flex-col gap-5  p-2 md:">
                <div className=" w-full flex flex-col md:flex-row  gap-3">
                  <TextInput
                    label="Nom de la Boutique"
                    loader={connectionLoader}
                    inputValue={storeNameValue}
                    handleInput={handleStoreNameChange}
                  />
                </div>
                <div className=" w-full flex flex-col md:flex-row  gap-3">
                  <TextInput
                    label="Apropos de Nous"
                    multiple={4}
                    loader={connectionLoader}
                    inputValue={storeDescriptionValue}
                    handleInput={handleStoreDescriptionlChange}
                  />
                </div>

                <div className="flex flex-col gap-3 w-full">
                  <TextInput
                    label="Notre Adresse"
                    loader={connectionLoader}
                    inputValue={storeAddressValue}
                    handleInput={handleStoreAddressChange}
                  />
                  <TextInput
                    label="Telephone"
                    loader={connectionLoader}
                    inputValue={storeTelephoneValue}
                    handleInput={handleStoreTelephoneChange}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center p-2">
              <LoadingBtn
                text="Enregistrer"
                loader={connectionLoader}
                checked={inputChecked}
                handleConnectionClick={handleConnectionClick}
              />
            </div>
            {/* <button onClick={testIt}>text</button> */}
          </PerfectScrollbar>
          {fetchStatut && (
            <DialogBox
              state={true}
              readOnly={true}
              btnTitle="Ma Boutique"
              className="w-full hidden"
              title={'Message'}
              content="Votre Boutique a été créé avec succes."
              button={{
                // link: '/store',
                title: 'Actualiser',
                handler: refreshHanler,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
