export const categories = [
  'adaptateurs',
  // "antivirus",
  'camera',
  'carte_memoire',
  'claviers',
  'cle_usb',
  'disque_dure',
  'ecouteurs',
  'moniteurs',
  'ordinateurs',
  'smartphones',
  'souris',
  'tablets',
]

export const shortCategories = [
  {
    name: 'Moniteurs',
    category: 'moniteurs',
    icon: require('../../assets/icons/monitors.png'),
  },
  {
    name: 'Ordinateurs',
    category: 'ordinateurs',
    icon: require('../../assets/icons/ordinateurs.png'),
  },
  {
    name: 'Smartphones',
    category: 'smartphones',
    icon: require('../../assets/icons/smartphones.png'),
  },
  {
    name: 'Tablets',
    category: 'tablets',
    icon: require('../../assets/icons/tablets.png'),
  },
  {
    name: 'Adaptateurs',
    category: 'adaptateurs',
    icon: require('../../assets/icons/adapters.png'),
  },
  {
    name: 'Disque Dure',
    category: 'disque_dure',
    icon: require('../../assets/icons/disque_dur.png'),
  },
  {
    name: 'Souris',
    category: 'souris',
    icon: require('../../assets/icons/mouse.png'),
  },
  {
    name: 'Camera',
    category: 'camera',
    icon: require('../../assets/icons/camera.png'),
  },
  {
    name: 'Ecouteurs',
    category: 'ecouteurs',
    icon: require('../../assets/icons/casques.png'),
  },
  {
    name: 'Clavier',
    category: 'claviers',
    icon: require('../../assets/icons/clavier.png'),
  },
  {
    name: 'Clé USB',
    category: 'cle_usb',
    icon: require('../../assets/icons/flash.png'),
  },
  {
    name: 'Imprimantes',
    category: 'imprimantes',
    icon: require('../../assets/icons/imprimantes.png'),
  },
]
