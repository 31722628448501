/* eslint-disable react-hooks/exhaustive-deps */
// import styled from '@emotion/styled'
import { Link, Navigate } from 'react-router-dom'
import { useState, useEffect, useCallback } from 'react'

// import { banners } from '../../data/Banners'
import { SessionContext } from '../../utils/Context'
import PasswordInput from '../../components/PasswordInput'
import LoadingBtn from '../../components/LoadingBtn'
import Button from '@mui/material/Button'
import TextInput from '../../components/TextInput'
import { PostHeader } from '../Login'
import DialogBox from '../../components/DialogBox'
import ConfirmEmail from '../ConfirnEmail'

export default function Register() {
  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)

  //state confirmEmail
  const [confirmEmailForm, setConfirmEmailForm] = useState()
  //fetch states
  const [fetchStatut, setFetchStatut] = useState(false)
  const [errorStatut, setErrorStatut] = useState(false)

  //input value States
  const [firstNameValue, setFirstNameValue] = useState('')
  const [lastNameValue, setLastNameValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [usernameValue, setUsernameValue] = useState('')
  const [passwordValue, setPasswordValue] = useState('')
  const [confirmPasswordValue, setConfirmPasswordValue] = useState('')
  const [telephoneValue, setTelephoneValue] = useState('')

  //filled input States
  const [filledFirstName, setFilledFirstName] = useState(false)
  const [filledLastName, setFilledLastName] = useState(false)
  const [filledEmail, setFilledEmail] = useState(false)
  const [filledUsername, setFilledUsername] = useState(false)
  const [filledPassword, setFilledPassword] = useState(false)
  const [filledConfirmPassword, setFilledConfirmPassword] = useState(false)
  const [filledTelephone, setFilledTelephone] = useState(false)

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  //error
  const [firstNameError, setFirstNameError] = useState({})
  const [lastNameError, setLastNameError] = useState({})
  const [emailError, setEmailError] = useState({})
  const [usernameError, setUsernameError] = useState({})
  const [passwordError, setPasswordError] = useState({})
  const [confirmPasswordError, setConfirmPasswordError] = useState({})
  const [telephoneError, setTelephoneError] = useState({})

  const handleUsernameError = (eState = false, eMessage = '') => {
    eState
      ? setUsernameError({ state: eState, message: '* ' + eMessage })
      : setUsernameError({})
  }
  const handlePasswordError = (eState = false, eMessage = '') => {
    eState
      ? setPasswordError({ state: eState, message: '* ' + eMessage })
      : setPasswordError({})
  }

  //Changes Handleres
  const handleFirstNameChange = (e) => {
    setFirstNameValue(e.target.value)
    checkFirstName()
  }
  const handleLastNameChange = (e) => {
    setLastNameValue(e.target.value)
    checkLastName()
  }
  const handleEmailChange = (e) => {
    setEmailValue(e.target.value)
    checkEmail()
  }
  const handleUsernameChange = (e) => {
    setUsernameValue(e.target.value)
    checkUsername()
  }
  const handlePasswordChange = (e) => {
    setPasswordValue(e.target.value)
    checkPassword()
  }
  const handleConfirmPasswordChange = (e) => {
    setConfirmPasswordValue(e.target.value)
    checkConfirmPassword()
  }
  const handleTelephoneChange = (e) => {
    setTelephoneValue(e.target.value)
    checkTelephone()
  }

  //Check handlers
  const checkFirstName = useCallback(() => {
    if (firstNameValue.length >= 4) {
      setFilledFirstName(true)
      CheckInputs()
    } else {
      setFilledFirstName(false)
    }
  })
  const checkLastName = useCallback(() => {
    if (lastNameValue.length >= 4) {
      setFilledLastName(true)
      CheckInputs()
    } else {
      setFilledLastName(false)
    }
  })

  //Mail REGX
  const checkEmail = useCallback(() => {
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (emailValue.match(mailformat)) {
      setFilledEmail(true)
      CheckInputs()
    } else {
      setFilledEmail(false)
    }
  })

  // function ValidateEmail(mail) {
  //   if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailValue)) {
  //     return true
  //   }
  //   alert('You have entered an invalid email address!')
  //   return false
  // }

  const checkUsername = useCallback(() => {
    // console.log(60)
    if (usernameValue.length >= 6) {
      // console.log(6)
      setFilledUsername(true)
      handleUsernameError()
      CheckInputs()
    } else {
      setFilledUsername(false)
      handleUsernameError(true, 'Username Must have more than 6 characters')
    }
  })
  const checkPassword = useCallback(() => {
    // console.log(80)
    if (passwordValue.length >= 8) {
      // console.log(8)
      setFilledPassword(true)
      handlePasswordError()
      CheckInputs()
    } else {
      setFilledPassword(false)
      handlePasswordError(true, 'Password Must have more than 8 characters')
    }
  })
  const checkConfirmPassword = useCallback(() => {
    if (
      confirmPasswordValue.length >= 8 &&
      confirmPasswordValue === passwordValue
    ) {
      setFilledConfirmPassword(true)
      CheckInputs()
    } else {
      setFilledConfirmPassword(false)
    }
  })
  const checkTelephone = useCallback(() => {
    // const phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
    // const phoneno2 = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{3})$/
    if (telephoneValue.length >= 10) {
      setFilledTelephone(true)
      CheckInputs()
    } else {
      setFilledTelephone(false)
    }
  })

  //Inputs General Check
  const CheckInputs = () => {
    if (
      filledFirstName === true &&
      filledLastName === true &&
      filledEmail === true &&
      filledUsername === true &&
      filledPassword === true &&
      filledConfirmPassword === true &&
      filledTelephone === true
    ) {
      handleChecks(true)
      // console.log(usernameValue)
      // console.log(passwordValue)
    } else {
      // console.log(1234)
      handleChecks(false)
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  const handleConnectionClick = () => {
    setConnectionLoader(true)

    //formData
    setConnectionLoader(true)
    const formdata = new FormData()
    formdata.append('action', 'action')
    formdata.append('first_name', firstNameValue)
    formdata.append('last_name', lastNameValue)
    formdata.append('telephone', telephoneValue)
    formdata.append('email', emailValue)
    formdata.append('username', usernameValue)
    formdata.append('password', passwordValue)

    postData(formdata)
  }

  const refreshHanler = () => {
    setConnectionLoader(false)
    setFetchStatut(false)
    window.location.reload(false)
  }
  const errorHanler = () => {
    setConnectionLoader(false)
    setErrorStatut()
  }

  //post data
  const postData = (data) => {
    console.log('posttt')
    fetch('https://store.kwetutech.com/api/authentication/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response === 'success') {
          setConfirmEmailForm({ email: emailValue })
          setConnectionLoader(false)
        } else {
          setErrorStatut({ message: response })
        }
        // setConnectionLoader(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setErrorStatut({
          message:
            'OPPS Something went wrong, please check your network and retry later',
        })
      })
    // setConnectionLoader(false)
  }

  useEffect(() => {
    checkFirstName()
    checkLastName()
    checkEmail()
    checkUsername()
    checkPassword()
    checkConfirmPassword()
    checkTelephone()
    CheckInputs()
  }, [
    firstNameValue,
    lastNameValue,
    emailValue,
    usernameValue,
    passwordValue,
    confirmPasswordValue,
    telephoneValue,
    inputChecked,
  ])

  const testIt = () => {
    console.log(filledFirstName)
    console.log(filledLastName)
    console.log(filledEmail)
    console.log(filledUsername)
    console.log(filledPassword)
    console.log(filledConfirmPassword)
    console.log(filledTelephone)
  }

  return (
    <SessionContext.Consumer>
      {({ loggedUser, startSession }) => (
        <div className="w-full flex justify-center items-center md:p-5 mb-20">
          {loggedUser ? (
            <Navigate to="/profile" replace={true} />
          ) : (
            <div className="flex flex-row justify-center items-center w-full h-full md:h-full  bg-tertiery rounded-xl p-5 md-max:mb- shadow-xs">
              <div className=" sm-max:w-full w-5/6  flex justify-center items-center">
                {confirmEmailForm ? (
                  <ConfirmEmail email={confirmEmailForm} />
                ) : (
                  <div className="md:p-5 w-full flex flex-col justify-center items-center gap-3">
                    <span className="font-semibold text-8 text-primary ">
                      Inscription
                    </span>
                    <form className="w-full flex flex-col justify-center gap-3">
                      <div className=" w-full flex flex-col md:flex-row  gap-3">
                        <TextInput
                          label="First Name"
                          loader={connectionLoader}
                          inputValue={firstNameValue}
                          handleInput={handleFirstNameChange}
                        />
                        <TextInput
                          label="Last Name"
                          loader={connectionLoader}
                          inputValue={lastNameValue}
                          handleInput={handleLastNameChange}
                        />
                      </div>
                      <div className="flex flex-col md:flex-row  gap-3">
                        <TextInput
                          label="Email"
                          loader={connectionLoader}
                          inputValue={emailValue}
                          handleInput={handleEmailChange}
                        />
                        <TextInput
                          label="Username"
                          loader={connectionLoader}
                          inputValue={usernameValue}
                          handleInput={handleUsernameChange}
                          error={usernameError}
                        />
                      </div>
                      <div className="flex flex-col md:flex-row  gap-3">
                        <PasswordInput
                          label="Password"
                          loader={connectionLoader}
                          inputValue={passwordValue}
                          handleInput={handlePasswordChange}
                          error={passwordError}
                        />
                        <PasswordInput
                          label="Confirm Password"
                          loader={connectionLoader}
                          inputValue={confirmPasswordValue}
                          handleInput={handleConfirmPasswordChange}
                          error={passwordError}
                        />
                      </div>
                      <TextInput
                        label="Telephone"
                        loader={connectionLoader}
                        inputValue={telephoneValue}
                        handleInput={handleTelephoneChange}
                      />
                      <LoadingBtn
                        text="S'INSCRIRE"
                        loader={connectionLoader}
                        checked={inputChecked}
                        handleConnectionClick={handleConnectionClick}
                      />
                      <Link
                        to="/login"
                        className="w-full flex flex-col gap-2 justify-center"
                      >
                        <Button className="w-full" variant="text">
                          Vous Avez deja un compte, Connexion
                        </Button>
                      </Link>

                      {/* <Button onClick={testIt} className="w-full" variant="text">
                test
              </Button> */}
                      {/* <DialogBox /> */}
                    </form>
                    {errorStatut && (
                      <DialogBox
                        state={true}
                        readOnly={true}
                        // btnTitle="Ma Boutique"
                        className="w-full hidden"
                        title={'Message'}
                        content={errorStatut.message}
                        button={{
                          // link: '/store',
                          title: 'Ok',
                          handler: errorHanler,
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </SessionContext.Consumer>
  )
}
