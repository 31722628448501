import pic from '../../assets/icons/adapters.jpg'

export const product = [
  {
    product_id: 4,
    product_name: 'Ordinateur',
    product_category: 'ordinateurs',
    product_price: 300,
    product_state: 'new',
    product_desc: '',
    unite_mesure: '',
    photo1: '952720221114_045323am.jpg',
    photo2: '639620221114_045324am.jpg',
    photo3: '820120221114_045325am.jpg',
    photo4: null,
    new_arrival: 'false',
    sponsored: 'no',
    suspended: 'no',
    userId: 20,
    user_id: 20,
    first_name: 'Ahadi',
    last_name: 'Pro',
    telephone: '+243999809343',
    email: 'ahadicizungujean11@gmail.com',
    username: 'Ahadi Pro',
    password: 'MDk5OTgwOTM0Mw==',
    privilege: 'user',
    max_limit: 3,
    verification: 'verified',
    verification_code: 'STORE-1456',
    date_created: '2022-11-03 07:21:28',
  },
  {
    product_id: 14,
    product_name: 'SanDisk  3.0 64GB',
    product_category: 'cle_usb',
    product_price: 15,
    product_state: 'new',
    product_desc: 'Flash disc original \nCapacit\u00e9 : 64GB\n',
    unite_mesure: 'piece',
    photo1: '986220221125_211523pm.jpg',
    photo2: '256120221125_211523pm.jpg',
    photo3: '906320221125_211523pm.jpg',
    photo4: null,
    new_arrival: 'false',
    sponsored: 'no',
    suspended: 'no',
    userId: 1,
    user_id: 1,
    first_name: 'Christian',
    last_name: 'Rusipa jerry',
    telephone: '+243979672761',
    email: 'chrisrusipa@gmail.com',
    username: '@christianjerry',
    password: 'a2FiYW5nNjND',
    privilege: 'administrator',
    max_limit: 100,
    verification: 'verified',
    verification_code: 'STORE-1126',
    date_created: '2021-11-25 07:14:52',
  },
]

export const data = [
  {
    product_id: 1,
    product_name: 'Moniteurs',
    product_category: 'moniteurs',
    product_price: 300,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/monitors.png'),
    photo2: require('../../assets/icons/hp1.JPG'),
    photo3: require('../../assets/icons/hp2.png'),
    photo4: null,
    new_arrival: true,
    sponsored: true,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 2,
    product_name: 'Ordinateurs',
    product_category: 'ordinateurs',
    product_price: 350,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/ordinateurs.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 3,
    product_name: 'Smartphones',
    product_category: 'smartphones',
    product_price: 150,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/smartphones.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 4,
    product_name: 'Tablets',
    product_category: 'tablets',
    product_price: 290,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/tablets.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: true,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 5,
    product_name: 'Adaptateurs',
    product_category: 'adaptateurs',
    product_price: 45,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/adapters.jpg'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: true,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 7,
    product_name: 'Disque Dure',
    product_category: 'disque_dure',
    product_state: 'new',
    product_price: 110,
    product_desc:
      'Mod\u00e8le : Seagate \nStockage :2To (2048GB)\nVitesse de copie :3.0',
    unite_mesure: '',
    photo1: require('../../assets/icons/disque_dur.jpg'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 8,
    product_name: 'Souris',
    product_category: 'souris',
    product_price: 15,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/mouse.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: true,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 9,
    product_name: 'Camera',
    product_category: 'camera',
    product_price: 150,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/camera.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 10,
    product_name: 'Ecouteurs',
    product_category: 'ecouteurs',
    product_price: 15,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/casques.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 11,
    product_name: 'Clavier',
    product_category: 'claviers',
    product_price: 25,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/clavier.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 12,
    product_name: 'Clé USB',
    product_category: 'cle_usb',
    product_price: 3,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/flash.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 13,
    product_name: 'Imprimantes',
    product_category: 'imprimantes',
    product_price: 450,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/imprimantes.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 14,
    product_name: 'Hp 2568',
    product_category: 'ordinateurs',
    product_price: 250,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/hp2.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 15,
    product_name: 'Ordination',
    product_category: 'ordinateurs',
    product_price: 350,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/ordinateurs.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 16,
    product_name: 'Ordination',
    product_category: 'ordinateurs',
    product_price: 350,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/ordinateurs.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 17,
    product_name: 'Ordination',
    product_category: 'ordinateurs',
    product_price: 350,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/ordinateurs.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
  {
    product_id: 18,
    product_name: 'Ordination',
    product_category: 'ordinateurs',
    product_price: 350,
    product_state: 'new',
    product_desc:
      'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut debitis, ducimus itaque ipsam harum inventore, non porro voluptas possimus quo illo ullam molestias minima dolorem pariatur doloremque, hic sit quidem.',
    unite_mesure: '',
    photo1: require('../../assets/icons/ordinateurs.png'),
    photo2: null,
    photo3: null,
    photo4: null,
    new_arrival: true,
    sponsored: false,
    suspended: false,
    userId: 20,
  },
]

export const link =
  'https://store.kwetutech.com/webservices/api/v1/images/tempfiles/'
