// import 'material-icons/iconfont/round.css'
// import Button from '@mui/material/Button'
import { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import PhotoCamera from '@mui/icons-material/PhotoCamera'
import styled from 'styled-components'

const Cover = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const date = new Date()

export default function ImageUpload({
  file,
  handleImagesUpload,
  handleImagesDelete,
  loader,
  className,
  name,
}) {
  // const [file, setFile] = useState('')
  function handleChange(e) {
    // file extention
    const file = e.target.files[0]
    // console.log(file)
    const ext = file.name.split('.')[1]
    const rString = Math.random().toString(36).substring(2, 7)
    const newFileName =
      'mykwetustore' + date.getTime().toString() + rString + '.' + ext
    // file.name = 150
    console.log(newFileName)

    // setFile(URL.createObjectURL(e.target.files[0]))
    handleImagesUpload({
      link: URL.createObjectURL(e.target.files[0]),
      file: e.target.files,
      fileName: newFileName,
    })
  }

  const remaneFile = (file) => {}
  // useEffect(() => {
  //   file && handleImagesUpload(file)
  // }, [file])

  function handleDelete() {
    console.log(file)
    handleImagesDelete(file)
    // setFile('')
  }
  return (
    <Cover
      img={file && file.link}
      className={
        'flex justify-center items-center h-32 w-32 border-2 border-dashed border-slate-DEFAULT-400 rounded-xl ' +
        className
      }
    >
      <div className="h-full w-full flex flex-col justify-center items-center hover:bg-slate-200 hover:bg-opacity-60 cursor-pointer">
        {!file && (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input
              value={file}
              hidden
              name={name && name}
              accept="image/*"
              onChange={handleChange}
              type="file"
            />
            <PhotoCamera size="large" style={{ height: '2rem' }} />
          </IconButton>
        )}
        {/* <input hidden accept="image/*" type="file" /> */}
        {file && !loader && (
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
            onClick={handleDelete}
          >
            <span className="material-icons-round text-red-500 text-center">
              {' '}
              delete
            </span>
          </IconButton>
        )}
        {/* <span className="material-icons-round text-primary text-center">
          add
        </span>
        <span className="text-secondary font-semibold">image</span> */}
      </div>
    </Cover>
  )
}
