import ReactSearchBox from 'react-search-box'
import SearchBar from '../../components/ProductsSeachBar'
import Card from '../../components/Card'
import { useState } from 'react'
import { useFetch } from '../../utils/hooks'
import { Link } from 'react-router-dom'
import { DisplayCategory } from '../../components/CategoryNavBar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import Loader from '../../components/Loader'
import DialogBox from '../../components/DialogBox'

function Search() {
  const [searchResults, setSearchResults] = useState([])
  const link =
    'https://store.kwetutech.com/webservices/api/v1/images/tempfiles/'
  const [isLoading, data, error] = useFetch(
    'https://store.kwetutech.com/webservices/api/v1/products'
  )

  const sendResultss = (datas) => {
    setSearchResults(datas)
  }
  return (
    <div>
      {error && (
        <DialogBox
          className="hidden"
          state={true}
          title="Erreur de Connexion"
          content="Veiller verifier votre connexion internet puis reessayer"
          readOnly={true}
          // button={{
          //   title: 'Logout',
          //   handler: () => {
          //     // destroySession()
          //     // handlerLogout()
          //   },
          // }}
        />
      )}
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col h-full w-full p-2  mb-20">
          <SearchBar data={data} sendResults={sendResultss} />
          <div className="flex flex-wrap justify-center mt-14 gap-5 py-3 px-2">
            {searchResults.length > 0 ? (
              searchResults.map(
                ({
                  product_category,
                  product_id,
                  product_name,
                  product_desc,
                  product_price,
                  product_state,
                  photo1,
                  sponsored,
                }) => (
                  <Link
                    className=""
                    key={product_name + product_id}
                    to={'/products/' + product_id}
                  >
                    <Card
                      image={photo1}
                      name={product_name}
                      price={product_price}
                      desc={product_desc}
                      sponsored={sponsored}
                      state={product_state}
                    />
                  </Link>
                )
              )
            ) : (
              <div className="flex flex-col gap-2 justify-center items-center">
                <DisplayCategory
                  // onClick={loadSponsored()}
                  icon={'star'}
                  name={'Sponsored'}
                />
                <div className="flex flex-wrap gap-5 justify-center items-center ">
                  {/* <div className=""> */}
                  {data &&
                    data.length > 0 &&
                    data.map(
                      ({
                        product_category,
                        product_id,
                        product_name,
                        product_desc,
                        product_price,
                        product_state,
                        photo1,
                        sponsored,
                      }) =>
                        sponsored && (
                          <Link
                            className=""
                            key={product_name + product_id}
                            to={'/products/' + product_id}
                          >
                            <Card
                              image={link + photo1}
                              name={product_name}
                              price={product_price}
                              desc={product_desc}
                              sponsored={sponsored}
                              state={product_state}
                            />
                          </Link>
                        )
                    )}
                  {/* </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Search
