/* eslint-disable react-hooks/exhaustive-deps */
import 'material-icons/iconfont/round.css'
import { useState, useEffect, useCallback } from 'react'
import { shortCategories } from '../../data/Products/Categories'
import TextInput from '../TextInput'
import ImageUpload from '../ImageUpload'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { PostHeader } from '../../pages/Login'
import DialogBox from '../DialogBox'
import LoadingBtn from '../LoadingBtn'

export default function Post({ handler, user }) {
  //file data
  const [product, setProduct] = useState({})

  const [fetchStatut, setFetchStatut] = useState(false)
  const [errorStatut, setErrorStatut] = useState()

  //loader State
  const [connectionLoader, setConnectionLoader] = useState(false)

  //input value States
  const [productNameValue, setProductNameValue] = useState('')
  const [productCategoryValue, setProductCategoryValue] = useState('')
  const [productDescriptionValue, setProductDescriptionlValue] = useState('')
  const [productPriceValue, setProductPriceValue] = useState('')
  const [productStateValue, setProductStateValue] = useState('')

  //filled input States
  const [filledProductName, setFilledProductName] = useState(false)
  const [filledProductCategory, setFilledProductCategory] = useState(false)
  const [filledProductDescription, setFilledProductDescription] =
    useState(false)
  const [filledProductPrice, setFilledProductPrice] = useState(false)
  const [filledProductState, setFilledProductState] = useState(false)
  const [filledImage1, setFilledImage1] = useState(false)
  const [filledImage2, setFilledImage2] = useState(false)
  const [filledImage3, setFilledImage3] = useState(false)
  const [filledImage4, setFilledImage4] = useState(false)

  //checking States
  const [inputChecked, setInputchecked] = useState(false)

  // images state
  const [image1, setImage1] = useState('')
  const [image2, setImage2] = useState('')
  const [image3, setImage3] = useState('')
  const [image4, setImage4] = useState('')

  //Changes Handleres
  const handleProductNameChange = (e) => {
    setProductNameValue(e.target.value)
    // console.log(productNameValue)
    checkProductName()
  }
  const handleProductCategory = (e) => {
    setProductCategoryValue(e.target.value)
    checkProductDescription()
  }
  const handleProductDescriptionlChange = (e) => {
    setProductDescriptionlValue(e.target.value)
    checkProductCategory()
  }
  const handleProductPriceChange = (e) => {
    setProductPriceValue(e.target.value)
    checkProductPrice()
  }
  const handleProductStateChange = (e) => {
    setProductStateValue(e.target.value)
    checkProductState()
  }

  const handleImage1Upload = (newImg) => {
    // console.log('newImg')
    setImage1(newImg)
    setFilledImage1(true)
    CheckInputs()
  }
  const handleImage1Delete = (newImg) => {
    // console.log('delete')
    setImage1('')
    setFilledImage1(false)
    CheckInputs()
  }
  const handleImage2Upload = (newImg) => {
    // console.log('newImg')
    setImage2(newImg)
    setFilledImage2(true)
    CheckInputs()
  }
  const handleImage2Delete = (newImg) => {
    // console.log('delete')
    setImage2('')
    setFilledImage2(false)
    CheckInputs()
  }
  const handleImage3Upload = (newImg) => {
    // console.log('newImg')
    setImage3(newImg)
    setFilledImage3(true)
    CheckInputs()
  }
  const handleImage3Delete = (newImg) => {
    // console.log('delete')
    setImage3('')
    setFilledImage3(false)
    CheckInputs()
  }
  const handleImage4Upload = (newImg) => {
    // console.log('newImg')
    setImage4(newImg)
    setFilledImage4(true)
    CheckInputs()
  }
  const handleImage4Delete = (newImg) => {
    // console.log('delete')
    setImage4('')
    setFilledImage4(false)
    CheckInputs()
  }

  //Check handlers
  const checkProductName = useCallback(() => {
    if (productNameValue.length >= 2) {
      setFilledProductName(true)
      CheckInputs()
    } else {
      setFilledProductName(false)
    }
  })
  const checkProductDescription = useCallback(() => {
    if (productDescriptionValue.length >= 6) {
      setFilledProductDescription(true)
      CheckInputs()
    } else {
      setFilledProductName(false)
    }
  })
  const checkProductCategory = useCallback(() => {
    if (productCategoryValue.length >= 3) {
      setFilledProductCategory(true)
      CheckInputs()
    } else {
      setFilledProductCategory(false)
    }
  })
  const checkProductPrice = useCallback(() => {
    if (productPriceValue.length >= 1) {
      setFilledProductPrice(true)
      CheckInputs()
    } else {
      setFilledProductPrice(false)
    }
  })
  const checkProductState = useCallback(() => {
    if (productStateValue.length >= 3) {
      setFilledProductState(true)
      CheckInputs()
    } else {
      setFilledProductState(false)
    }
  })

  //Inputs General Check
  const CheckInputs = () => {
    if (
      filledProductName === true &&
      filledProductCategory === true &&
      filledProductDescription === true &&
      filledProductPrice === true &&
      filledProductState === true &&
      (filledImage1 || filledImage2 || filledImage3 || filledImage4)
    ) {
      handleChecks(true)
      // console.log(usernameValue)
      // console.log(passwordValue)
    } else {
      // console.log(1234)
      handleChecks(false)
    }
  }

  const handleChecks = (state) => {
    setInputchecked(state)
  }

  const handleConnectionClick = () => {
    setConnectionLoader(true)

    //api call
    const formdata = new FormData()
    formdata.append('submit', 'submit')
    formdata.append('product_name', productNameValue)
    formdata.append('product_category', productCategoryValue)
    formdata.append('product_price', productPriceValue)
    formdata.append('product_state', productStateValue)
    formdata.append('product_desc', productDescriptionValue)
    formdata.append('unite_mesure', '')
    formdata.append('userId', user.userId)
    image1 &&
      image1.file[0] &&
      formdata.append('photos[]', image1.file[0], image1.fileName)
    image2 &&
      image2.file[0] &&
      formdata.append('photos[]', image2.file[0], image2.fileName)
    image3 &&
      image3.file[0] &&
      formdata.append('photos[]', image3.file[0], image3.fileName)
    image4 &&
      image4.file[0] &&
      formdata.append('photos[]', image4.file[0], image4.fileName)
    // const prod = {
    //   product_name: productNameValue,
    //   product_category: productCategoryValue,
    //   product_desc: productDescriptionValue,
    //   product_price: productPriceValue,
    //   product_state: productStateValue,
    //   image1: image1,
    //   image2: image2,
    //   image3: image3,
    //   image4: image4,
    // }
    // setConnectionLoader(false)
    console.log(15)
    postData(formdata)

    // console.log(prod)
    // setProduct(prod)
    // setConnectionLoader(false)
  }

  const refreshHanler = () => {
    handler()
    setConnectionLoader(false)
    setFetchStatut(false)
    window.location.reload(false)
  }
  const errorHanler = () => {
    setConnectionLoader(false)
    setErrorStatut()
  }

  //post data
  const postData = (data) => {
    console.log('posttt')
    fetch('https://store.kwetutech.com/api/controller.php', {
      method: 'POST',
      headers: PostHeader,
      body: data,
    })
      .then((response) => response.json())
      .then((response) => {
        response === 'success'
          ? setFetchStatut(true)
          : setErrorStatut({ message: response })
        // setConnectionLoader(false)
      })
      .catch((error) => {
        console.error('Error:', error)
        setErrorStatut({
          message:
            'OPPS Something went wrong, please check your network and retry later',
        })
      })
    // setConnectionLoader(false)
  }
  //effect listener
  useEffect(() => {
    checkProductName()
    checkProductDescription()
    checkProductCategory()
    checkProductPrice()
    checkProductState()
  }, [
    filledProductName,
    filledProductCategory,
    filledProductDescription,
    filledProductPrice,
    filledProductState,
    filledImage1,
    filledImage2,
    filledImage3,
    filledImage4,
  ])

  const Categories = shortCategories.map(({ name, category }) => {
    return {
      value: category,
      label: name,
    }
  })

  const Etats = [
    {
      value: 'Neuf',
      label: 'Neuf',
    },
    {
      value: 'Seconde Main',
      label: 'Seconde Main',
    },
  ]

  const testIt = () => {
    console.log(filledProductName)
    console.log(filledProductCategory)
    console.log(filledProductDescription)
    console.log(filledProductPrice)
    console.log(filledProductState)
    console.log(filledImage1)
  }

  return (
    <div className="fixed flex justify-center items-center md-max:h-full md:min-h-full w-full bg-bg bg-opacity-90 pb-5 ">
      <div className="flex justify-center items-center h-full w-full md:mr-30 lg:mr-68 p-2 md:p-5 ">
        <div className="bg-tertiery flex flex-col h-full w-full rounded-xl shadow-xs p-2 md:p-5">
          <PerfectScrollbar className="h-full w-full md-max:mb-10 md-max:overflow-y-scroll">
            <div className="h-10 flex justify-between items-center">
              <span className="ml-5 md:text-6 font font-semibold text-primary">
                Ajouter Un Produit
              </span>
              <button
                onClick={handler}
                className="material-icons-round text-10 font-semibold text-primary hover:text-red-500"
              >
                close
              </button>
            </div>

            <div className="flex flex-col gap-5  p-2 md:">
              <div className=" w-full flex flex-col md:flex-row  gap-3">
                <TextInput
                  label="Nom du Produit"
                  loader={connectionLoader}
                  inputValue={productNameValue}
                  handleInput={handleProductNameChange}
                />

                <TextInput
                  label="Categorie"
                  loader={connectionLoader}
                  inputValue={productCategoryValue}
                  handleInput={handleProductCategory}
                  data={Categories}
                />
              </div>
              <div className=" w-full flex flex-col md:flex-row  gap-3">
                <TextInput
                  label="Bref Descriptions du Produit"
                  multiple={4}
                  loader={connectionLoader}
                  inputValue={productDescriptionValue}
                  handleInput={handleProductDescriptionlChange}
                />

                <div className="flex flex-col gap-3 w-full">
                  <TextInput
                    label="Prix du Produit"
                    loader={connectionLoader}
                    inputValue={productPriceValue}
                    handleInput={handleProductPriceChange}
                  />
                  <TextInput
                    label="Etat / Conditions"
                    loader={connectionLoader}
                    inputValue={productStateValue}
                    data={Etats}
                    handleInput={handleProductStateChange}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-5  flex-wrap p-2 md:">
              <ImageUpload
                loader={connectionLoader}
                file={image1}
                handleImagesUpload={handleImage1Upload}
                handleImagesDelete={handleImage1Delete}
              />
              {(image1 || image2) && (
                <ImageUpload
                  loader={connectionLoader}
                  file={image2}
                  handleImagesUpload={handleImage2Upload}
                  handleImagesDelete={handleImage2Delete}
                />
              )}
              {(image2 || image3) && (
                <ImageUpload
                  loader={connectionLoader}
                  file={image3}
                  handleImagesUpload={handleImage3Upload}
                  handleImagesDelete={handleImage3Delete}
                />
              )}
              {(image3 || image4) && (
                <ImageUpload
                  loader={connectionLoader}
                  file={image4}
                  handleImagesUpload={handleImage4Upload}
                  handleImagesDelete={handleImage4Delete}
                />
              )}
            </div>
            <div className="w-full flex justify-center p-2">
              <LoadingBtn
                text="Partager"
                loader={connectionLoader}
                checked={inputChecked}
                handleConnectionClick={handleConnectionClick}
              />
            </div>
            {/* <button onClick={testIt}>text</button> */}
          </PerfectScrollbar>
          {fetchStatut && (
            <DialogBox
              state={true}
              readOnly={true}
              btnTitle="Ma Boutique"
              className="w-full hidden"
              title={'Message'}
              content="Votre Produit a été ajouté avec succes."
              button={{
                // link: '/store',
                title: 'Actualiser',
                handler: refreshHanler,
              }}
            />
          )}
          {errorStatut && (
            <DialogBox
              state={true}
              readOnly={true}
              btnTitle="Ma Boutique"
              className="w-full hidden"
              title={'Message'}
              content={errorStatut.message}
              button={{
                // link: '/store',
                title: 'Ok',
                handler: errorHanler,
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}
