// import { useState } from 'react'
import { Link } from 'react-router-dom'
import CategoryNavBar from '../../components/CategoryNavBar'
import { CategoryContext } from '../../utils/Context'
// import { data } from '../../data/Products'
// import MyAlert from '../../components/MyAlert'
import Card from '../../components/Card'
import { useFetch } from '../../utils/hooks'
// import { useLocation } from 'react-router-dom'
import Loader from '../../components/Loader'
import MyAlert from '../../components/MyAlert'
import DialogBox from '../../components/DialogBox'

const link = 'https://store.kwetutech.com/webservices/api/v1/images/tempfiles/'

function Home() {
  // const location = useLocation()
  // const path = location.pathname
  // const loc = path.split('/')[2]
  // const [exist, setExist] = useState(false)

  const [isLoading, data, error] = useFetch(
    'https://store.kwetutech.com/webservices/api/v1/products'
  )

  return (
    <CategoryContext.Consumer>
      {({ selectedCategory, selectCategory }) => (
        <div className="w-full p-3 lg:px-5 md:px-5">
          <CategoryNavBar />
          {error && (
            <DialogBox
              className="hidden"
              state={true}
              title="Erreur de Connexion"
              content="Veiller verifier votre connexion internet puis reessayer"
              readOnly={true}
              // button={{
              //   title: 'Logout',
              //   handler: () => {
              //     // destroySession()
              //     // handlerLogout()
              //   },
              // }}
            />
          )}
          {isLoading ? (
            <Loader />
          ) : (
            data.length > 0 && (
              <div className="flex flex-wrap justify-center gap-5 p-3 mb-20">
                {!error &&
                  !data.find((prod) => {
                    return prod.product_category.includes(selectedCategory)
                  }) && (
                    <div>
                      <DialogBox
                        className="hidden"
                        state={true}
                        title="Message"
                        content="Pas de Produits pour cette categorie"
                        readOnly={true}
                        // button={{
                        //   title: 'Logout',
                        //   handler: () => {
                        //     // destroySession()
                        //     // handlerLogout()
                        //   },
                        // }}
                      />
                      {/* <MyAlert type="warning" message="Pas des Produits" /> */}
                    </div>
                  )}
                {data.map(
                  ({
                    product_category,
                    product_id,
                    product_name,
                    product_desc,
                    product_price,
                    product_state,
                    photo1,
                    sponsored,
                  }) =>
                    product_category.includes(selectedCategory) && (
                      <Link
                        className=""
                        key={product_name + product_id}
                        to={'/products/' + product_id}
                      >
                        <Card
                          image={link + photo1}
                          name={product_name}
                          price={product_price}
                          desc={product_desc}
                          sponsored={sponsored}
                          state={product_state}
                        />
                      </Link>
                    )
                )}
              </div>
            )
          )}
        </div>
      )}
    </CategoryContext.Consumer>
  )
}

export default Home
