import { Link } from 'react-router-dom'
import { useFetch } from '../../utils/hooks'
import { useState } from 'react'
// import { stores } from '../../data/Stores'
import Loader from '../../components/Loader'
import StoreCard from '../../components/StoreCard'
import StoresSearchBar from '../../components/StoresSeachBar'
import DialogBox from '../../components/DialogBox'

function Stores() {
  const [searchResults, setSearchResults] = useState([])

  const [isLoading, stores, error] = useFetch(
    'https://store.kwetutech.com/api/controller.php?fetch_all_shops'
  )
  console.log(stores)
  // const openedStores = stores.filter((store) => {
  //   return store.shop_status === 'open'
  // })

  const sendResults = (data) => {
    setSearchResults(data)
  }

  return (
    <div className="w-full md:p-5 p-2">
      {stores && <StoresSearchBar sendResults={sendResults} stores={stores} />}

      {error && (
        <DialogBox
          className="hidden"
          state={true}
          title="Erreur de Connexion"
          content="Veiller verifier votre connexion internet puis reessayer"
          readOnly={true}
          button={{
            title: 'Reesayer',
            handler: () => {
              window.location.reload(false)
            },
          }}
        />
      )}

      {isLoading ? (
        <Loader />
      ) : (
        stores && stores.length > 0 ? (
          <div className="flex flex-wrap justify-center mt-14 w-full p-2 md:p-5 mb-20 gap-2 md:gap-5">
            {searchResults.length && searchResults.length > 0 ? (
              searchResults.map((store) => (-
                <Link
                  key={store.shop_name + store.shop_id}
                  to={'/stores/' + store.shop_id}
                >
                  <StoreCard data={store} />
                </Link>
              ))
            )  : (
              <Link
                key={stores.shop_name + stores.shop_id}
                to={'/stores/' + stores.shop_id}
              >
                <StoreCard data={stores} />
              </Link>
            )}
          </div>
        ) : (
          <div className="flex flex-wrap justify-center mt-14 w-full p-2 md:p-5 mb-20 gap-2 md:gap-5">

          <Link
                key={stores.shop_name + stores.shop_id}
                to={'/stores/' + stores.shop_id}
              >
                <StoreCard data={stores} />
              </Link>
          </div>
        )
      )}
    </div>
  )
}

export default Stores
