import Error404 from '../../assets/404.svg'

function Error() {
    return (
        <div className="flex justify-center p-5">
            <img src={Error404} alt="" srcset="" />
        </div>
    )
}

export default Error
